import styled from 'styled-components'

const Content = styled.div`    
  padding-left: 3.5rem;
  padding-right: 3.5rem;

  .title-slide, .subtitle {
    text-align: center;    
  } 

  .subtitle {
    margin-top: -2.8rem;    
  }

  @media (min-width: 1080px) and (max-width: 1480px) {
    .title-slide {
      font-size: 23px;
    }

    .subtitle {
      font-size: 20px;
      margin-top: -3.8rem;    
    }
  }
`

const ContainerChildren = styled.div`
  margin-bottom: 1rem;
  margin-top: 1.4rem;
`

export {
  Content,
  ContainerChildren
}