/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { A_Image } from "../../atomos/image";
import { A_TextBody } from "../../atomos/text-body";
import { ContainerFlex, PrincipalContainer } from "./styles";

interface IIconProps {
  image: any;
  title: string;
  text: string;
  colorTitle: string;
  colorText: string;
  heightIcon: string;
  widthIcon: string;
  fontSizeTitle: string;
  fontSizeText: string;
  className?: string;
  classNameBoxAll?: string;
}

const M_BoxInfo: FC<IIconProps> = ({
  title,
  text,
  colorText,
  colorTitle,
  widthIcon,
  fontSizeTitle,
  fontSizeText,
  heightIcon,
  image,
  className,
  classNameBoxAll,
}) => {
  return (
    <PrincipalContainer className={classNameBoxAll}>
      <A_Image
        alt={"image"}
        width={widthIcon}
        src={image}
        height={heightIcon}
      />
      <ContainerFlex className={className}>
        <A_TextBody
          content={title}
          fontFamily={"Nunito"}
          fontSize={fontSizeTitle}
          fontWeight={"400"}
          color={colorTitle}
          toUppercase={false}
        />

        <A_TextBody
          content={text}
          fontFamily={"Nunito"}
          fontSize={fontSizeText}
          fontWeight={"400"}
          color={colorText}
          toUppercase={false}
        />
      </ContainerFlex>
    </PrincipalContainer>
  );
};

export { M_BoxInfo };
