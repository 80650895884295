import styled from 'styled-components'

interface IImageProps {
  width: string
  height: string
}

const Image = styled.img<IImageProps>`
  width: ${props => props.width};
  height: ${props => props.height};
`

export {
  Image
}