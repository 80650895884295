import styled from "styled-components";

interface IButtonProps {
  background: string;
  color: string
  width: string
  height: string;
}

export const Button = styled.button<IButtonProps>`
  background: ${props => props.background};
  color: ${props => props.color};
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: 2rem;   
  padding: .5rem;
  cursor: pointer;
  transition: .2;
  border: none;
`