import { FC, ReactNode } from "react";
import { Container } from "./styles";

interface ISessionProps {
  children: ReactNode;
  heigth: string;
  background: string;
  mobile?: string;
  tabled?: string;
  className?: string;
}

const O_Session: FC<ISessionProps> = ({
  mobile,
  children,
  heigth,
  background,
  className,
  tabled
}) => (
  <Container
    heigth={heigth}
    background={background}
    mobile={mobile}
    tabled={tabled}
    className={className}
  >
    {children}
  </Container>
);

export { O_Session };
