/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { A_Title } from "../../components/atomos/title";
import { M_BackgroundTitleImage } from "../../components/moleculas/background-title-image";
import { O_Header } from "../../components/organismos/header";
import { T_Footer } from "../../components/templates/footer";
import { ContainerAll } from "./styles";
import { O_ContentTermos } from "../../components/organismos/content-termos";

export const P_Termos: FC = () => (
  <ContainerAll>
    <O_Header session="pageTermos" />
    <M_BackgroundTitleImage
      sessionPageBackground="pageTermos"
      children={
        <A_Title
          title={"Termos de adesão e uso de conta Planium Bank"}
          color={"#fff"}
          fontFamily={"Nunito"}
          fontWeigth={"500"}
          fontSize={"2rem "}
          toUppercase={true}
          className={"title"}
          notPaddingTop={false}
        />
      }
    />
    <O_ContentTermos />
    <T_Footer />
  </ContainerAll>
);
