import styled from 'styled-components'

const PrincipalContainer = styled.div`
  max-width: 1200px;
  margin: auto; 
  height: 100%;
  display: flex;
  flex-direction: column;

  .text-rodape {
    margin-top: 3rem;
  }

  @media(max-width: 900px) {
    .text-rodape {
      text-align: center;
      padding: 0rem 1rem;
    }
  }  
`;

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);   
  padding-top: 3rem;

  .container-apps {
    gap: 0;    

    .title-apps {
      margin-bottom: 1rem;
    }
  }

  .container-politicas {
    padding-left: 1rem;
  }

  @media(max-width: 800px) and (max-width: 1000px) {   
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;   
    gap: 2.5rem;          

    .container-politicas {
      padding-left: 0rem;
    }

    .container-contatos {
      width: 100%;      
      padding-left: 1.5rem;
    }
  } 

  @media(max-width: 767px) {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;    

    .container-politicas, .container-contatos {
      padding-left: 0rem;
    }

  }  
`

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export {
  PrincipalContainer,
  ContainerGrid,
  ContainerFlex
}