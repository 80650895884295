/* eslint-disable react/jsx-pascal-case */
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import {
  PrincipalContainer,
  HeaderForm,
  ContainerAction,
  ContainerCheck,
  ContainerLogo,
  ContainerLoading,
} from "./styles";
import { A_Image } from "../../atomos/image";
import { A_TextBody } from "../../atomos/text-body";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TextInput } from "../../atomos/input";
import { ValidationTypes } from "../../../utils/messages/valiationTypes";
import { ValidarCpf } from "../../../utils/validation/validarCpf";
import { ValidaTelefone } from "../../../utils/validation/validaTelefone";
import {
  MessageSuccess,
  MessageValidation,
} from "../../../utils/messages/messages";
import { ValidarCnpj } from "../../../utils/validation/validarCnpj";
import { A_Button } from "../../atomos/button";
import { Checkbox, CircularProgress } from "@mui/material";
import { IconButton } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { handleAberturaDeConta } from "../../../utils/requests/handle-abertura-de-conta";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleDemonstracao } from "../../../utils/requests/handle-solicitar-demonstracao";
import { Navigate } from "react-router-dom";
const logoPremia = require("../../../assets/img-logo-premia.png");
const logoAntecipa = require("../../../assets/img-logo-antecipa.png");
const logoBoletos = require("../../../assets/img-logo-boleto.png");
const logoDefault = require("../../../assets/img-logo-cor-letra-preta.png");

interface IFormProps {
  title: string;
  titleButton: string;
  subtitle: string;
  isLogoExist: boolean;
  logo?: "premia" | "antecipa" | "boletos" | "default";
  onCloseModal: () => void;
  request: "openAccount" | "demonstration";
  existPersonalData?: boolean
}

interface IDadosProps {
  cpf: string;
  email: string;
  telefone: string;
  nome: string;
  cnpj: string;
}

const M_Form: FC<IFormProps> = ({
  title,
  onCloseModal,
  titleButton,
  isLogoExist,
  logo,
  subtitle,
  request,
  existPersonalData = false
}) => {
  const [dados, setDados] = useState<IDadosProps>({
    cnpj: "",
    cpf: "",
    email: "",
    nome: "",
    telefone: "",
  });
  const formSb = useRef(null);
  const [url, setUrl] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [termosCheck, setTermosCheck] = useState(true);
  const [pessoaJuridica, setPessoaJuridica] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const resetDados = () => {
    setDados({
      ...dados,
      nome: "",
      email: "",
      telefone: "",
      cpf: "",
      cnpj: "",
    });
  };

  const handleClickTermoCheck = () => {
    setTermosCheck(!termosCheck);
  };

  const handleClickPessoaJuridica = () => {
    setPessoaJuridica(!pessoaJuridica);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDados({
      ...dados,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogo = useMemo(() => {
    switch (logo) {
      case "premia":
        return logoPremia;

      case "antecipa":
        return logoAntecipa;

      case "boletos":
        return logoBoletos;

      case "default":
        return logoDefault;
    }
  }, [logo]);

  useEffect(() => {
    try {
      ValidatorForm.addValidationRule(
        ValidationTypes.IsCPF,
        (value: string) => {

          if (!ValidarCpf.cpf(value)) {
            setInvalid(true)
          }
            
          setInvalid(false)
          return ValidarCpf.cpf(value);
        }
      );

      ValidatorForm.addValidationRule(
        ValidationTypes.IsCNPJ,
        (value: string) => {
          return ValidarCnpj.cnpj(value);
        }
      );

      ValidatorForm.addValidationRule(
        ValidationTypes.IsTelefone,
        (value: any) => {
          return ValidaTelefone.LenghtTest(value);
        }
      );
    } catch (error) {
      throw error;
    }
  });

  const handleSubmitAberturaDeConta = async () => {
    if (
      dados.nome === "" ||
      dados.telefone === "" ||
      dados.email === ""
    ) {
      toast.info(MessageValidation.AllFieldsRequired);
    } else {
      setLoading(true);
      await handleAberturaDeConta(dados)
        .then(() => {
          setUrl("/messagemdeconfirmacao");
          setRedirect(true);
          resetDados();
          setLoading(false);
          onCloseModal();
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(MessageValidation.SubmitForm);
        });
    }
  };

  const handleSubimitDemostration = async () => {
    if (
      dados.nome === "" ||
      dados.telefone === "" ||
      dados.email === ""
    ) {
      toast.info(MessageValidation.AllFieldsRequired);
    } else {
      setLoading(true);
      await handleDemonstracao(dados)
        .then(() => {
          resetDados();
          setLoading(false);
          toast.success(MessageSuccess.SubmitForm);
        })
        .catch(() => {
          setLoading(false);
          toast.error(MessageValidation.SubmitForm);
        });
    }
  };

  const handleSubimit = (typeRequest: string) => {
    switch (typeRequest) {
      case "openAccount":
        handleSubmitAberturaDeConta();
        break;

      case "demonstration":
        handleSubimitDemostration();
        break;
    }
  };

  return (
    <PrincipalContainer open={pessoaJuridica}>
      {redirect ? <Navigate to={url} /> : <></>}
      <ToastContainer autoClose={2000} />

      {loading ? (
        <ContainerLoading>
          <CircularProgress />
        </ContainerLoading>
      ) : (
        <>
          {isLogoExist ? (
            <ContainerLogo logo={String(logo)}>
              <A_Image alt={"logo"} width={""} src={handleLogo} height={""} />
            </ContainerLogo>
          ) : (
            <></>
          )}

          <HeaderForm>
            {title !== "" && (
              <A_TextBody
                content={title}
                fontFamily={"Cabin"}
                fontSize={"1.25rem"}
                fontWeight={"400"}
                color={"#000"}
                toUppercase={true}
                className="title"
              />
            )}
            <A_TextBody
              content={subtitle}
              fontFamily={"Roboto"}
              fontSize={".75rem"}
              fontWeight={"400"}
              color={"#8D8D8D"}
              toUppercase={false}
            />
          </HeaderForm>

          <ValidatorForm
            ref={formSb}
            onError={() => { }}
            onSubmit={() => { }}
            className="form"
          >
            <TextInput
              type={"text"}
              name={"nome"}
              value={dados.nome}
              label={"Nome"}
              onChange={handleChange}
            />

            <TextInput
              type={"text"}
              name={"email"}
              value={dados.email}
              label={"E-mail"}
              onChange={handleChange}
              validators={[ValidationTypes.Required, ValidationTypes.IsEmail]}
              errorMessages={[
                MessageValidation.Required,
                MessageValidation.EmailInvalid,
              ]}
            />

            <TextInput
              type={"text"}
              name={"telefone"}
              value={dados.telefone}
              label={"Telefone"}
              mask={"(99) 99999-9999"}
              onChange={handleChange}
              validators={[
                ValidationTypes.Required,
                ValidationTypes.IsTelefone,
              ]}
              errorMessages={[
                MessageValidation.Required,
                MessageValidation.TelefoneInavalid,
              ]}
            />

            {existPersonalData && (
              <TextInput
                type={"text"}
                name={"cpf"}
                value={dados.cpf}
                label={"CPF"}
                mask={"999.999.999-99"}
                onChange={handleChange}
                validators={["isCPF"]}
                errorMessages={[
                  MessageValidation.CpfInvalid,
                ]}
              />
            )}

            {existPersonalData && (

              <ContainerCheck>
                <Checkbox
                  checked={pessoaJuridica}
                  onClick={handleClickPessoaJuridica}
                />
                <span>Sou pessoa jurídica</span>
              </ContainerCheck>
            )}

            {pessoaJuridica ? (
              <TextInput
                type={"text"}
                name={"cnpj"}
                value={dados.cnpj}
                label={"CNPJ"}
                mask={"99.999.999/9999-99"}
                onChange={handleChange}
                validators={["isCNPJ"]}
                errorMessages={[                  
                  MessageValidation.CnpjInavalid,
                ]}
              />
            ) : (
              <></>
            )}

            <ContainerCheck className="termosPoliticaProvacidade">
              <Checkbox checked={termosCheck} onClick={handleClickTermoCheck} />
              <span>
                Estou de acordo com a{" "}
                <a href="https://drive.google.com/file/d/1XYvWwoffHVS3c-dvK3xQ5FZ8bAyNV_Dw/view">
                  Política de privacidade
                </a>{" "}
                e <a href="/nossostermos">Termos de adesão</a> e autorizo o
                contato.
              </span>
            </ContainerCheck>

            <ContainerAction>
              <A_Button
                background={"#31ccd9"}
                color={"#fff"}
                width={"15rem"}
                height={"3.6rem"}
                className="button"                
                onClick={() => handleSubimit(request)}
              >
                {titleButton}
              </A_Button>
            </ContainerAction>
          </ValidatorForm>
        </>
      )}

      <IconButton className="icon-close" onClick={onCloseModal}>
        <AiOutlineClose />
      </IconButton>
    </PrincipalContainer>
  );
};

export { M_Form };
