import styled from "styled-components";

interface IImageFile {
  imageFile: string;
}

const PrincipalContainer = styled.div<IImageFile>`
  background: no-repeat center/cover url(${props => props.imageFile});
  width: 100vw;
  height: auto;  
`;

const Content = styled.div`
  max-width: 650px;  
  height: auto;
  margin: 0 auto 3rem auto;
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding-top: 4rem;

  @media (max-width: 700px) {
    max-width: 90%;        
  }
`;

const PrincipalBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;  

  .month {
    padding-right: 1rem;
  }

  .container-none-after {
    ::after {
      display: none;
    }
  }

  .container-infos-na-midia {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    gap: 0.5rem;    
  }

  @media (max-width: 700px) {
    .container-infos-na-midia {
      padding-left: 1rem;
      width: 95%;
      word-break: break-all;
    } 
  }
`;

const DataBox = styled.div`
  display: flex;
  align-items: center;

  .mouth {
    margin-right: 1rem;
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: center;
  gap: 1.5rem;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    background: linear-gradient(#8062f7, #33c2db);
    height: 100%;
    width: 2px;
  }
`;

export { PrincipalContainer, PrincipalBox, ContainerFlex, Content, DataBox };
