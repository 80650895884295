/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { O_Session } from "../../organismos/section";
import {
  ContainerOne,
  ContainerTwo,
  Content,
  ContainerFlex,
  Line,
} from "./styles";
import { A_TextBody } from "../../atomos/text-body";
import { A_Image } from "../../atomos/image";
import { A_TextLink } from "../../atomos/text-link";

const backgroundOne = require("../../../assets/img-home-second.png");
const backgroundTwo = require("../../../assets/img-home-firsh.png");
const imgVideo = require("../../../assets/img-video.png");
const imgWoman = require("../../../assets/img-woman.png");
const iconPlay = require("../../../assets/img-icon-home.png");

const T_Home: FC = () => {
  return (
    <>
      <O_Session
        heigth={"750px"}
        mobile={"750px"}
        tabled={"750px"}
        background={"#F1F2F6"}
        children={
          <ContainerOne imageFile={backgroundOne}>
            <ContainerTwo imageFile={backgroundTwo}>
              <Content>
                <ContainerFlex>
                  <A_TextBody
                    content={
                      "Soluções Financeiras para Operadoras e Canais de Venda da Saúde Suplementar."
                    }
                    fontFamily={"Cabin"}
                    fontSize={"1.6rem"}
                    fontWeight={"400"}
                    color={"#000"}
                    toUppercase={true}
                    className={"title"}
                  />
                  <ContainerFlex className="container-subtitle">
                    <A_TextBody
                      content={
                        "Veja como o Planium Bank automatiza sua gestão financeira"
                      }
                      fontFamily={"Cabin"}
                      fontSize={"21.5px"}
                      fontWeight={"400"}
                      color={"#000"}
                      toUppercase={false}
                      className="subtitle"
                    />
                  </ContainerFlex>

                  <iframe
                    src="https://player.vimeo.com/video/584884202?h=961d276666"
                    className="iframe-home"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </ContainerFlex>
                <ContainerFlex>
                  <A_Image
                    alt={"image-woman"}
                    width={"35rem"}
                    src={imgWoman}
                    height={"750px"}
                    className="image-woman"
                  />
                </ContainerFlex>
              </Content>
            </ContainerTwo>
          </ContainerOne>
        }
      />
      <Line />
    </>
  );
};

export { T_Home };
