import styled from "styled-components";

export const ContainerAll = styled.div`
  .title {
    z-index: 9;
  }

  @media(max-width: 700px) {  
    .title {
      font-size: 1.55rem;
      text-align: center;
      width: 350px;
    }
  }
`;
