/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { M_BoxInfo } from "../../moleculas/box-info";
import { M_ContainerClients } from "../../moleculas/container-session-clients";
import { M_FooterReferenceClients } from "../../moleculas/footer-reference-clients";
import { O_Session } from "../../organismos/section";
import { PrincipalContainer, ContainerGrid } from "./styles";

const background = require("../../../assets/img-background.png");

const iconBoletos = require("../../../assets/img-icon-boletos.png");
const iconInteracao = require("../../../assets/img-icon-interacao.png");
const iconParceiros = require("../../../assets/img-icon-parceiros.png");

const T_Operador: FC = () => {
  return (
    <>
      <O_Session
       heigth={"auto"}
       mobile={"78rem"}
       tabled="53rem"
        background={""}
        className="session"
        children={
          <PrincipalContainer imageFile={background}>
            <M_ContainerClients
              title={"Operadora"}
              colorText={"#000"}
              colorTitle={"#000"}
              subTitle={"Centralize seus recebimentos"}
              children={
                <>
                  <ContainerGrid>
                    <M_BoxInfo
                      image={iconBoletos}
                      title={"Cobranças"}
                      text={
                        "Recebimentos através de PIX, boleto ou cartão com aviso automático para as plataformas de vendas, corretoras e corretores."
                      }
                      colorTitle={"#55595F"}
                      colorText={"#000"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      widthIcon={"4rem"}
                      heightIcon={"3.5rem"}
                    />

                    <M_BoxInfo
                      image={iconParceiros}
                      title={"Pagamentos Centralizados"}
                      text={
                        "Conta específica para pagamento de Prêmios, Agenciamentos e Comissões. Sem custo de transferência para corretoras e corretores."
                      }
                      colorTitle={"#55595F"}
                      colorText={"#000"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      widthIcon={"4rem"}
                      heightIcon={"3.5rem"}
                    />

                    <M_BoxInfo
                      image={iconInteracao}
                      title={"Integração"}
                      text={
                        "API's para integração com as principais plataformas de venda onLineSession e sistemas de gestão."
                      }
                      colorTitle={"#55595F"}
                      colorText={"#000"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      widthIcon={"4rem"}
                      heightIcon={"3.5rem"}
                    />
                  </ContainerGrid>
                  <M_FooterReferenceClients
                    title={
                      '"Todos os meses nossa equipe valida cerca de 20.000 notas fiscais para pagamento de comissão para corretoras parceiras. A verificação dos valores e dos impostos associados é um processo que consome milhares de horas de trabalho. Automatizar este processo com o Planium Bank não só elimina este problema como reduz sensivelmente os nossos prazos de pagamento."'
                    }
                    text={"Uma das 10 maiores medicinas de grupo do país."}
                    colorTitle={"#000"}
                    colorText={"#000"}
                  />                  
                </>
              }
            />
          </PrincipalContainer>
        }
      />
    </>
  );
};

export { T_Operador };
