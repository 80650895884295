/* eslint-disable react/jsx-pascal-case */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { A_Image } from "../../atomos/image";
import { A_TextBody } from "../../atomos/text-body";
import { PrincipalContainer } from "./styles";

const imgArrow = require("../../../assets/img-arrow.png");

interface ICardProps {
  title: string;
  text: string;
}

const M_CardSession: FC<ICardProps> = ({ title, text }) => {
  return (
    <PrincipalContainer>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <A_Image
              alt={"img-arrow"}
              width={"1.8rem"}
              src={imgArrow}
              height={"1rem"}
              className="img-arrow"
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <A_TextBody
              content={title}
              fontFamily={"Nunito"}
              fontSize={"1rem"}
              fontWeight={"700"}
              color={"#fff"}
              toUppercase={true}
              className="title"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <A_TextBody
              content={text}
              fontFamily={"Nunito"}
              fontSize={"1rem"}
              fontWeight={"400"}
              color={"#fff"}
              toUppercase={false}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </PrincipalContainer>
  );
};

export { M_CardSession };
