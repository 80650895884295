/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { O_Session } from "../section";
import { PrincipalContainer } from "./styles";
import { A_TextBody } from "../../atomos/text-body";

const O_ContentTermos: FC = () => {
  return (
    <>
      <O_Session
        heigth={"auto"}
        background={"#fff"}
        children={
          <PrincipalContainer>
            <A_TextBody
              content={`
                    Termo de Adesão que firmam, entre si, o CLIENTE que ao final e eletronicamente o assina, e a PLANIUM INSTITUICAO DE PAGAMENTO SA, pessoa jurídica inscrita no CNPJ/MF sob o nº 38.314.747/0001-97, com sede na Av. Flamboyants da Península, 100, Bloco 3 Sala 412, Barra da Tijuca, Rio de Janeiro – RJ, CEP: 22.776-070, doravante denominado PLANIUM BANK, para regular os direitos e obrigações das partes na contratação, utilização e manutenção do serviço denominado CONTA DIGITAL PLANIUM , doravante denominada simplesmente CONTA, que será operacionalizada por meio de website e/ou smartphones e com uso dos canais de atendimento que a PLANIUM BANK dispuser para sua utilização, que será regido pelas seguintes cláusulas e condições: <br /> <br />
                    
                    <b>CLÁUSULA PRIMEIRA – DAS DEFINIÇÕES</b> <br />
                    1.1 O presente termo de adesão prevê, e as partes acordam, as seguintes definições: 
                    <br />
                    <br />

                    a) APLICATIVO: Software disponibilizado pela PLANIUM BANK, concebido para processar dados eletronicamente, desempenhando tarefas práticas ao CLIENTE, para que esse possa concretizar suas transações de consulta ou movimentação financeira. <br /> <br />
                    
                    b) ASSINATURA ELETRÔNICA: Qualquer mecanismo eletrônico disponibilizado pela PLANIUM BANK que permita a identificação da Pessoa Natural. <br /><br />
                                    
                    c) BANDEIRA: VISA, que cede o direito de usar sua marca e sua rede de ESTABELECIMENTOS no Brasil e no exterior, para a aceitação do CARTÃO, inclusive para a realização de TRANSAÇÕES na função débito e crédito, este último mediante aprovação de cadastro. <br /><br />
                                        
                    d) CARTÃO: Cartão de plástico emitido e administrado pela PLANIUM BANK, na modalidade pré-paga, recarregável e vinculado a uma conta de pagamento, para realizar TRANSAÇÕES em estabelecimentos afiliados à bandeira VISA. <br /><br />
                    
                    e) CENTRAL DE ATENDIMENTO AO CLIENTE (CAC): Central telefônica que presta atendimento (ativo e receptivo) ao CLIENTE quanto às TRANSAÇÕES realizadas e demais assuntos relacionados ao CARTÃO e à CONTA DIGITAL PLANIUM BANK, tais como dúvidas, contestações, taxas, tarifas e tributos, entre outras. Os telefones do CAC estão indicados no verso do CARTÃO, no aplicativo PLANIUM BANK ou em outros meios de comunicação disponibilizados pelo EMISSOR. <br /><br />
                    
                    f) CLIENTE: Pessoa Natural, com idade mínima de 18 anos completos, CLIENTE de conta de pagamento pré-paga mantida na PLANIUM BANK, independentemente da existência de outros relacionamentos com instituições financeiras brasileiras. <br /><br />
                    
                    g) CONTA: Conta de depósito pré-paga, aberta e mantida pelo CLIENTE, de acordo com a legislação e regulamentação em vigor, na qual são debitados e/ou creditados os valores de todas as TRANSAÇÕES realizadas com ou sem cartão, na FUNÇÃO DÉBITO, inclusive, as tarifas inerentes ao uso da conta. <br /><br />
                    
                    h) CONTA DIGITAL PLANIUM BANK: Conta de pagamento Pré-Paga, também tratada neste instrumento como “CONTA”, aberta com a finalidade de possibilitar o recebimento, pagamento ou transferências de recursos financeiros pertencentes ao CLIENTE, movimentável por meios digitais e canais de atendimento disponibilizados e dentro dos limites máximos estabelecidos pelo EMISSOR. <br /><br />
                    
                    i) DISPOSITIVO: Equipamento, consistente em smartphones e/ou tablets, com configuração que permita a instalação, conexão e a utilização do APLICATIVO DIGITAL PLANIUM BANK e suas funcionalidades, para movimentação da CONTA. <br /><br />
                    
                    j) DEPÓSITO: Crédito realizado na CONTA DIGITAL PLANIUM BANK por meio de DEPÓSITO via boleto bancário, transferência interna e recebimento de TED, respeitando os limites e horários estabelecidos pelo EMISSOR. <br /><br />
                    
                    l) MEIOS DIGITAIS OU MEIOS ELETRÔNICOS: Nos termos definidos pelo Conselho Monetário e pelo Banco Central do Brasil, são considerados meios eletrônicos, a internet, os terminais de autoatendimento, o telefone e outros meios de comunicação à distância, tornados disponíveis pela PLANIUM BANK para fins de relacionamento e de formalização de transação financeira ou de adesão a produtos e/ou serviços. <br /><br />
                    
                    m) MENSALIDADE: Tarifa mensal, cobrada durante a vigência do Termo, conforme tabela e condições específicas disponíveis para consulta no APLICATIVO DIGITAL PLANIUM BANK.<br /><br /> 
                    
                    n) MOVIMENTAÇÃO: Qualquer transação financeira, a débito ou a crédito, efetuada em conta mantida pelo CLIENTE junto a PLANIUM BANK. <br /><br />                    
                    
                    o) PACOTE DE SERVIÇOS: Conjunto de produtos e serviços oferecidos pelo EMISSOR ao CLIENTE, por uma única tarifa mensal, contemplando a quantidade máxima de operações ofertadas gratuitamente e cuja extrapolação implicará no pagamento de tarifas diferenciadas para sua efetivação. Poderá ser disponibilizado em diversas modalidades e será escolhido pelo CLIENTE de acordo com as suas necessidades. <br /><br />

                    p) RECARGA DE CELULAR: Serviço de recarga para celular pré-pago que pode ser realizado pelo próprio celular ou em estabelecimentos cadastrados, de acordo com as condições previstas no APLICATIVO DIGITAL PLANIUM BANK. <br /><br />
                                        
                    q) SENHA ELETRÔNICA: Código secreto, pessoal e intransferível, gerado por iniciativa e escolha do CLIENTE e somente de seu conhecimento, que será utilizada para acesso ao APLICATIVO DIGITAL PLANIUM BANK e a efetivação de operações, financeiras ou não, relacionadas a sua conta, e que expressa seu conhecimento e autorização para sua realização. <br /><br />

                    r) TARIFAS: Valores fixados e cobrados pelo EMISSOR por serviços prestados ao CLIENTE, nos termos de Tabela específica disponível para consulta no APLICATIVO DIGITAL PLANIUM BANK, para todos os fins de direito. Seus valores e condições podem ser alterados a critério do EMISSOR, observadas as disposições e prazos legais, mediante aviso prévio ao CLIENTE. <br /><br />
                    
                    s) TABELA DE TARIFAS: Demonstrativo dos valores cobrados a título de tarifas de serviços estabelecidos pelo EMISSOR, em consonância com a legislação e regulamentação em vigor, sujeitas a alterações, e com valores constantes em lista disponível no FAQ. <br /><br />
                    
                    t) TRANSAÇÃO: Toda e qualquer movimentação financeira realizada na CONTA DIGITAL PLANIUM BANK.<br /><br />

                    <b>CLÁUSULA SEGUNDA – DA ADESÃO</b> <br />

                    2.1 Pelo presente instrumento, o CLIENTE manifesta sua intenção de aderir a CONTA DIGITAL PLANIUM que lhe será disponibilizada pelo EMISSOR, declarando total conhecimento das regras e condições a seguir descritas:<br /><br />

                    a) Por se tratar de conta eletrônica, a mesma será movimentável por meios eletrônicos;<br /><br />

                    b) A CONTA DIGITAL PLANIUM não se confunde com conta corrente de livre movimentação, e não se propõe a oferecer ou conceder linhas de crédito ou investimentos ou movimentação por meio de cheques;<br /><br />

                    c) A abertura e manutenção da CONTA DIGITAL PLANIUM estão condicionadas à avaliação, pelo EMISSOR, das informações cadastrais prestadas pelo CLIENTE, podendo sua abertura ser rejeitada, por critérios internos, uma vez não satisfeitas as condições gerenciais da PLANIUM BANK ou devido a incorreções dessas informações;<br /><br />

                    d) A PLANIUM BANK poderá, a seu exclusivo critério, na abertura ou durante a manutenção da conta, exigir dados, documentos e declarações que entenda necessários à perfeita identificação, qualificação, localização e conhecimento do CLIENTE em especial, mas não se limitando, às informações necessárias a prevenção e ao combate à lavagem de dinheiro e financiamento ao terrorismo.<br /><br />

                    e) A recusa do CLIENTE no fornecimento de dados, informações, documentos ou declarações solicitadas pelo EMISSOR poderá, a critério deste, ensejar a não realização do cadastro para abertura da conta e/ou o bloqueio e encerramento da conta;<br /><br />

                    f) A CONTA DIGITAL PLANIUM será movimentada exclusivamente pelo CLIENTE por meio do DISPOSITIVO, cartão com chip e, ainda, em canais de atendimento disponibilizados pelo EMISSOR, com a realização de depósitos, transferências entre contas, pagamento de obrigações, débitos e créditos de diversas origens ou natureza, em moeda nacional, ou por qualquer outra forma não vedada em lei;<br /><br />

                    g) O acesso por meio eletrônico a CONTA DIGITAL PLANIUM dar-se-á mediante utilização da SENHA ELETRÔNICA de uso pessoal e intransferível, cadastrada por este junto ao APLICATIVO, ou por identificação biométrica;<br /><br />

                    h) As transações financeiras geradas por iniciativa do CLIENTE somente serão concluídas mediante a aposição de senha de efetivação, cujo sequencial numérico foi previamente escolhido por este quando da abertura da conta, ou posteriormente alterada, não se responsabilizando o EMISSOR por quaisquer prejuízos advindos da utilização das senhas ou de qualquer outro mecanismo de identificação do CLIENTE devido ao seu uso por qualquer outra pessoa que não este, ainda que por ele autorizada.<br /><br />

                    i) O CLIENTE desde já autoriza o EMISSOR a enviar o cartão de débito de bandeira VISA devidamente bloqueado, para o endereço por ele fornecido quando da abertura da conta, via correios, podendo o cartão ser entregue a qualquer pessoa no endereço indicado, cabendo ao CLIENTE efetuar o seu desbloqueio, pelos meios disponibilizados para tal no próprio APLICATIVO.<br /><br />

                    j) O EMISSOR poderá, a seu critério e por meio de débito em conta, cobrar do CLIENTE um valor, que lhe será informado na tabela de tarifas, pela confecção e entrega do cartão com chip;<br /><br />

                    k) O CLIENTE autoriza a PLANIUM BANK a efetivar quaisquer operações ou transações solicitadas por meio eletrônico ou por qualquer outro meio que venha a ser disponibilizado pelo EMISSOR, mediante utilização de senha, e reconhece, desde já, para todos os efeitos legais, como válidas e verdadeiras as operações assim realizadas;<br /><br />

                    l) O EMISSOR estabelecerá limites de valor e de horário para a realização de operações nos meios eletrônicos disponíveis ou quaisquer outros que venham a ser disponibilizados, bem como para compras em estabelecimentos com o cartão com chip na função débito, que resultem em movimentação financeira. Os referidos limites serão divulgados em seus canais de atendimento.<br /><br />

                    2.2 O CLIENTE declara-se ciente de que:<br /><br />

                    a) não serão processadas as transações efetuadas após o horário fixado e/ou com valor superior ao limite estabelecido, bem como de que a liberação de saques em terminais eletrônicos, inclusive nos ﬁnais de semana, feriados ou em horário noturno, está condicionada à existência de saldo, já deduzidos eventuais débitos programados para o primeiro dia útil seguinte;<br /><br />

                    b) não será possível o cancelamento das transações financeiras efetuadas em sua conta por meios eletrônicos, após a confirmação da operação;<br /><br />

                    c) para sua segurança, contatos realizados por telefone poderão ser gravados pelo EMISSOR autorizando, desde já, tal procedimento;<br /><br />

                    d) sempre que definido pela PLANIUM BANK, a soma dos créditos depositados na Conta DIGITAL PLANIUM BANK não poderá ultrapassar os limites mensais que porventura venha a ser instituído. Os referidos limites serão divulgados em seus canais de atendimento.<br /><br />

                    e) está sujeito à cobrança de tarifas sobre os serviços, e desde já autoriza o EMISSOR a debitar em sua conta aqueles referentes a produtos ou serviços por ele solicitados e/ou utilizados ou decorrentes de normas do Banco Central do Brasil – BACEN, conforme Tabela de Tarifas disponibilizadas pelo EMISSOR, na forma da regulamentação vigente;<br /><br />

                    f) está sujeito e autoriza a realização do débito mensal no valor do pacote de serviços contratado, em sua conta;<br /><br />

                    g) o débito do valor das tarifas será efetuado pelo valor de cada serviço, em tantos lançamentos quantas forem suas ocorrências ou de forma agrupada, na conta do CLIENTE;<br /><br />

                    h) deverá observar e cumprir com as leis que dispõem sobre a prevenção e combate aos crimes de lavagem de dinheiro e de financiamento ao terrorismo e as normas complementares editadas pelo Banco Central do Brasil (BACEN) e Conselho Monetário Nacional (CMN) e, ainda, têm ciência de que o EMISSOR, por força dessas leis e normas, está obrigada a comunicar às autoridades competentes a ocorrência de operações previstas nas referidas normas;<br /><br />

                    i) o EMISSOR poderá bloquear, pelo tempo necessário à apuração do fato, e/ou encerrar a CONTA, em caso de suspeitas de fraude na documentação ou nas informações apresentadas, bem como em caso de descumprimento das normas que dispõem sobre a prevenção e combate aos crimes de lavagem de dinheiro e de financiamento ao terrorismo, podendo o EMISSOR adotar todos os procedimentos necessários à devolução de recursos eventualmente depositados a bancos emissores, nos termos da legislação e regulamentação em vigor;<br /><br />

                    j) não haverá pagamento de juros nem qualquer outra remuneração sobre o saldo da CONTA;<br /><br />

                    k) cada TRANSAÇÃO somente é autorizada se houver saldo suficiente e disponível na CONTA para cobrir o valor da TRANSAÇÃO e de eventuais TARIFAS, tributos e encargos incidentes;<br /><br />

                    l) deverá guardar os comprovantes de operações efetuadas com o CARTÃO, por um período de, no mínimo, 60 (sessenta) dias. Tais comprovantes podem ser solicitados pelo EMISSOR para averiguação das operações;<br /><br />

                    m) a CONTA DIGITAL PLANIUM BANK não possui programa de recompensas e benefícios do EMISSOR, com acúmulo de pontos relacionado ao uso do CARTÃO ou efetivação de qualquer TRANSAÇÃO financeira;<br /><br />

                    n) por ser emitido exclusivamente na modalidade pré-pago, o uso do CARTÃO e as TRANSAÇÕES financeiras estão sempre condicionados à disponibilidade de saldo na CONTA;<br /><br />

                    o) não haverá devolução de tarifas e mensalidade.<br /><br />

                    <b>CLÁUSULA TERCEIRA – DO CARTÃO</b><br /><br />

                    I – DAS CARACTERÍSTICAS E DO USO DO CARTÃO<br /><br />

                    3.1 Características:<br /><br />

                    a) O CARTÃO é de uso pessoal e intransferível. No seu verso ou anverso, entre outros dados poderão constar: nome do CLIENTE, seu número de identificação, data de validade do plástico, tarja magnética, holograma de segurança, logomarca da BANDEIRA e chip. Ao receber o CARTÃO, o CLIENTE deverá conferir os dados nele constantes.<br /><br />

                    b) o CARTÃO será emitido automaticamente após um depósito, de qualquer valor, na CONTA DIGITAL PLANIUM BANK.<br /><br />

                    c) O CARTÃO será entregue ao CLIENTE bloqueado para utilização. O desbloqueio do CARTÃO deverá ser solicitado pelo CLIENTE por meio do APLICATIVO DIGITAL PLANIUM BANK, conforme instruções gravadas no documento que acompanha o cartão.<br /><br />

                    d) Havendo necessidade de substituição do CARTÃO, será atribuído um novo número de identificação, ficando cancelado o número anterior e impossibilitada a sua reutilização. O EMISSOR está isento, desde já, de qualquer responsabilidade decorrente do uso indevido do CARTÃO e/ou da SENHA.<br /><br />

                    e) O EMISSOR reserva-se o direito de estabelecer limites financeiros diários de utilização dos CARTÕES, como medida de mitigação de ações fraudulentas.<br /><br />

                    f) O CLIENTE obriga-se a manter o EMISSOR sempre atualizado acerca de seu endereço para correspondência, de seu número de telefone celular e de outros dados constantes de seu cadastro, sendo de sua exclusiva responsabilidade todas as consequência as decorrentes da omissão dessa obrigação.<br /><br />

                    II – DAS FUNÇÕES E FINALIDADES DO CARTÃO<br /><br />

                    3.2 Finalidades:<br /><br />

                    a) Efetuar Pagamento(s)/Compra(s): quando o CARTÃO é usado, em um ESTABELECIMENTO, para promover o pagamento de uma compra de um bem ou de um serviço, na função débito.<br /><br />

                    b) Efetuar Saque(s): quando o CARTÃO é usado para a retirada de quantias em dinheiro, realizada mediante o uso do CARTÃO, nos caixas credenciados pelo EMISSOR ou pela BANDEIRA. O valor de cada retirada está sujeito às práticas do sistema bancário.<br /><br />

                    III – DA UTILIZAÇÃO DO CARTÃO NA FUNÇÃO DÉBITO<br /><br />

                    a) A utilização do CARTÃO na função débito e crédito, bem como as condições gerais de emissão e utilização do CARTÃO observarão o disposto no presente Termo de Adesão e Uso de Conta.<br /><br />

                    b) A utilização do CARTÃO na função débito está limitada ao saldo disponível na CONTA.<br /><br />

                    c) Será disponibilizado ao CLIENTE, via APLICATIVO, extrato para conferência das TRANSAÇÕES realizadas na CONTA.<br /><br />

                    d) A utilização do CARTÃO na função débito dar-se-á sempre com o uso exclusivo em equipamentos eletrônicos, o que exige a presença do CLIENTE no ato da TRANSAÇÃO, mediante validação positiva com digitação de SENHA pessoal e intransferível e/ou outras informações pessoais exigidas pelo equipamento.<br /><br />

                    e) Observado o previsto nas cláusulas antecedentes, ao CLIENTE será possível realizar as seguintes TRANSAÇÕES na função débito:<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;i) Pagamentos de contas e outros serviços que venham a ser criados e cuja utilização se dê por meio eletrônico, nos postos de atendimento<br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;conveniados com a PLANIUM BANK, ainda que distintas, e do EMISSOR.<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ii) Saques e compras de bens e serviços em ESTABELECIMENTO no Brasil.<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;iii) As compras realizadas nos ESTABELECIMENTOS ocorrerão mediante apresentação do CARTÃO e digitação da SENHA. Cabe ao<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;CLIENTE verificar a correção dos dados da operação, sendo certo que a digitação da SENHA significará integral responsabilidade pela<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;TRANSAÇÃO.<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;iv) A anulação ou cancelamento de qualquer compra efetuada pelo CLIENTE, somente poderá ocorrer no mesmo dia da TRANSAÇÃO, <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; mediante autorização que o ESTABELECIMENTO fará ao EMISSOR. Fica expressamente ressalvado que se a autorização de anulação ou<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;cancelamento for realizada em data posterior à compra, ela será totalmente ineficaz em relação ao EMISSOR, cabendo ao PORTADOR<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;entender-se diretamente com o ESTABELECIMENTO, mantendo o EMISSOR a salvo de qualquer responsabilidade pelo ocorrido.<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;vi) Eventual negativa, recusa ou restrição para aceite do CARTÃO, feitas pelos ﬁliados ou por qualquer caixa ou equipamento eletrônico<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;identificados com a BANDEIRA constante no CARTÃO, decorrem exclusivamente de política interna dos próprios ﬁliados e/ou gestor do <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; caixa ou equipamento eletrônico, não tendo o EMISSOR qualquer responsabilidade por tais recusas ou restrições.<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;vii) Caso o CLIENTE constate qualquer erro na TRANSAÇÃO efetuada com o CARTÃO, este deverá notificar imediatamente o EMISSOR<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;por meio da CENTRAL DE ATENDIMENTO AO CLIENTE (CAC). O CLIENTE poderá ser solicitado a fornecer os comprovantes<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;da TRANSAÇÃO para demonstração e identificação do erro.<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;viii) Podem incidir sobre a CONTA determinadas TARIFAS (emissão do CARTÃO, TARIFAS de saque, MENSALIDADE, transferência, envio<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;de 2ª via do CARTÃO, recarga telefônica, dentre outras). Tais TARIFAS poderão ser consultadas no APLICATIVO DIGITAL PLANIUM BANK <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;e serão debitadas pelo EMISSOR da CONTA de pagamento.<br /><br />


                    IV – DA SEGURANÇA NA UTILIZAÇÃO DO CARTÃO<br /><br />


                    a) O CLIENTE deverá zelar pela segurança do seu CARTÃO na qualidade de fiel depositário, guardando-o(s) em lugar seguro, sendo, também, responsável pelo sigilo de sua SENHA, mantendo-a sempre separada do CARTÃO.<br /><br />


                    b) O CLIENTE autoriza expressamente o EMISSOR a suspender, bloquear preventivamente ou cancelar o CARTÃO na hipótese de suspeita de impropriedade na sua utilização, fraude ou descumprimento da legislação e regulamentação em vigor.<br /><br />


                    c) O bloqueio preventivo do CARTÃO será baseado no histórico de uso do CARTÃO e da CONTA DIGITAL PLANIUM BANK pelo CLIENTE, podendo o EMISSOR entrar em contato com o CLIENTE a ﬁm de solicitar a confirmação da TRANSAÇÃO. Caso não sejam satisfatórias as informações prestadas pelo CLIENTE, o CARTÃO permanecerá bloqueado como medida de segurança.<br /><br />


                    d) A suspensão, o bloqueio ou o cancelamento não implicam o afastamento da responsabilidade da CLIENTE pelo pagamento das transações efetuadas pelo CLIENTE até o momento do bloqueio do CARTÃO.<br /><br />


                    e) O CLIENTE obriga-se a comunicar à CENTRAL DE ATENDIMENTO AO CLIENTE (CAC), imediatamente após a ocorrência do fato, a perda, o roubo, o furto ou o extravio do CARTÃO, mesmo que vencido ou cancelado, fornecendo elementos esclarecedores para que o EMISSOR possa tomar as medidas necessárias, a ﬁm de evitar sua utilização por terceiros. Caso o CARTÃO seja cancelado, este não poderá mais ser utilizado. O CARTÃO cancelado, por qualquer motivo, não poderá ser reativado.<br /><br />


                    f) O CLIENTE responderá para todos os fins de direito, pelo uso indevido do CARTÃO, que terceiros hajam feito ou venham a fazer, até o momento exato da comunicação ao EMISSOR. A partir do momento da comunicação, o número do CARTÃO será bloqueado e a utilização indevida não será mais de sua responsabilidade. A comunicação deverá ser realizada, via telefone à CENTRAL DE ATENDIMENTO AO CLIENTE (CAC).<br /><br />


                    g) O CARTÃO será bloqueado após 3 (três) tentativas consecutivas de uso com senha inválida. Após 24 (vinte e quatro) horas do bloqueio, a senha é desbloqueada automaticamente.<br /><br />


                    <b>CLÁUSULA QUARTA – DAS OBRIGAÇÕES DO CLIENTE</b><br /><br />


                    a) Possuir equipamento, consistente em Smartphone ou Tablet com as configurações mínimas necessárias a suportar a realização das consultas e/ou transações financeiras que lhe forem disponibilizadas pelo EMISSOR;<br /><br />


                    b) Prestar todas as informações solicitadas e manter seus dados cadastrais atualizados junto ao EMISSOR;<br /><br />


                    c) Manter saldo em conta para a quitação das obrigações assumidas, inclusive aquelas relacionadas a tarifas e taxas que incidirem sobre a utilização da CONTA DIGITAL PLANIUM BANK;<br /><br />


                    d) Fornecer informações verdadeiras, que permitam o EMISSOR avaliar sua capacidade econômico-financeira;<br /><br />


                    e) Informar ao EMISSOR qualquer alteração nos dados cadastrais, inclusive endereço e telefone prestando, ainda, quaisquer informações que lhe forem solicitadas por este;<br /><br />


                    f) Providenciar a guarda do cartão com chip, utilizado para a movimentação da CONTA DIGITAL PLANIUM BANK, preservando-o do alcance de terceiros;<br /><br />


                    g) Não fornecer ou divulgar a terceiros, as informações relativas às senhas e códigos secretos utilizados para acesso, consultas ou efetivação de transações financeiras, assumindo quaisquer danos ou prejuízos advindos da não observância da presente obrigação;<br /><br />


                    h) Cumprir rigorosamente os procedimentos descritos no presente instrumento, respondendo, nos termos da lei civil ou criminal, por atos, ações e procedimentos que vier a adotar, em desrespeito a legislação vigente e legal.<br /><br />


                    <b>CLÁUSULA QUINTA – DAS OBRIGAÇÕES DA PLANIUM BANK</b><br /><br />


                    a) Fornecer ao CLIENTE aplicativo hábil, que permita a completa realização das consultas e transações financeiras por ele desejadas, garantindo sua efetivação nos casos em que não haja impeditivos para tal;<br /><br />


                    b) Garantir o encaminhamento aos bancos, empresas ou clientes da PLANIUM BANK, de eventuais créditos cujos comandos tenham sido realizados pelo CLIENTE;<br /><br />


                    c) Assegurar, quanto aos procedimentos e as tecnologias utilizadas na abertura e no encerramento de contas de depósitos por meio eletrônico:<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;i) integridade, autenticidade e confidencialidade das informações e dos documentos eletrônicos utilizados;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ii) proteção contra o acesso, o uso, a alteração, a reprodução e a destruição não autorizadas das informações e documentos eletrônicos;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;iii) produção de cópia de segurança das informações e dos documentos eletrônicos;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;iv) rastreamento e auditoria dos procedimentos e das tecnologias empregados no processo.<br /><br />


                    <b>CLÁUSULA SEXTA – DA ISENÇÃO DE RESPONSABILIDADES</b><br /><br />


                    6.1 A PLANIUM BANK não se responsabilizará, a qualquer tempo:<br /><br />


                    a) Por quaisquer inconsistências que possam vir a impedir a efetivação de operações comandadas pelo CLIENTE, causadas por informações divergentes, incompletas, inverídicas ou irregulares geradas por este;<br /><br />


                    b) Por quaisquer ressarcimentos advindos a partir de ações comandadas pelo CLIENTE, como:<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;b.1. Pagamentos ou transferências financeiras realizadas em duplicidade;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;b.2. Pagamento de qualquer obrigação que apresente dados ou informações inconsistentes, irregulares ou indevidas;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;b.3. Não quitação de obrigações ou quaisquer outras transações financeiras por ausência de saldo em conta corrente;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;b.4. Rejeições indevidas por empresas, bancos ou quaisquer outros destinatários de créditos comandados pelo CLIENTE, <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;independentemente do motivo alegado;<br /><br />


                    c) por eventuais prejuízos decorrentes da utilização das senhas e da identificação positiva e seu uso por qualquer outra pessoa que não seja o CLIENTE, ainda que por ele autorizada.<br /><br />


                    <b>CLÁUSULA SÉTIMA – DO BLOQUEIO / ENCERRAMENTO DA CONTA</b><br /><br />


                    7.1 É facultado ao CLIENTE ou ao EMISSOR, de maneira unilateral, a solicitação de encerramento da conta, mediante manifestação pelo APLICATIVO, no caso de iniciativa do CLIENTE, ou por meio de comunicação da PLANIUM BANK com antecedência mínima de 30 dias.<br /><br />


                    Parágrafo segundo: Nos casos em que couber ao EMISSOR a iniciativa do encerramento da CONTA, este emitirá, além da comunicação citada nesta cláusula, um “TERMO DE ENCERRAMENTO” informando-lhe a data efetiva da descontinuidade do relacionamento. Cabe ao CLIENTE, as providências devidas para a retirada do valor disponível como saldo, no prazo de 30 dias do recebimento da comunicação de intenção de encerramento, emitida pelo EMISSOR.<br /><br />

                    Parágrafo segundo: Nos casos em que couber ao EMISSOR a iniciativa do encerramento da CONTA, este emitirá, além da comunicação citada nesta cláusula, um “TERMO DE ENCERRAMENTO” informando-lhe a data efetiva da descontinuidade do relacionamento. Cabe ao CLIENTE, as providências devidas para a retirada do valor disponível como saldo, no prazo de 30 dias do recebimento da comunicação de intenção de encerramento, emitida pelo EMISSOR.<br /><br />

                    Parágrafo terceiro: A CONTA que permanecer por 360 dias corridos sem movimentação, será considerada inativa, o que ensejará por parte do EMISSOR, as providências de início do processo de encerramento.<br /><br />

                    Parágrafo quarto: Constituirão, ainda, motivos para a rescisão deste instrumento e consequente cancelamento/bloqueio da conta e do cartão, além de outros previstos neste Termo:<br /><br />

                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;i) O descumprimento de qualquer cláusula deste contrato;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ii) A verificação pelo EMISSOR de serem inverídicas e/ou insuficientes as informações prestadas pelo CLIENTE; as pela Secretaria<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; da Receita Federal do Brasil, podendo o EMISSOR, a seu exclusivo critério, fazer prévia comunicação ao CLIENTE solicitando a  <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;regularização da situação cadastral do CPF naquele órgão, para evitar o encerramento da conta;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;iii) A prática dolosa de qualquer ato ou omissão que, de qualquer forma, possa ter concorrido para proporcionar para si ou para terceiros,<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;vantagens ilícitas decorrentes do uso indevido do cartão;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;iv) A não prestação de informações essenciais para atendimento as normas de Prevenção e Lavagem de Dinheiro e Financiamento<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ao Terrorismo.<br /><br />


                    Parágrafo terceiro: A CONTA que permanecer por 360 dias corridos sem movimentação, será considerada inativa, o que ensejará por parte do EMISSOR, as providências de início do processo de encerramento.<br /><br />

                    Parágrafo quinto: O EMISSOR poderá efetuar o bloqueio da conta nos casos em que for demandado judicialmente ou, ainda, devido a ocorrência de movimentação em valores superiores aos limites mensais movimentáveis, estabelecidos por essa.<br /><br />

                    Parágrafo sexto: Nos casos em que o bloqueio se faça em virtude da extrapolação dos limites movimentáveis, citado no parágrafo anterior, poderá o CLIENTE solicitar, pelos canais de atendimento disponibilizados pelo EMISSOR, a migração dos recursos para outra instituição financeira, por meio dos mecanismos bancários de transferência.<br /><br />

                    Parágrafo sétimo: Na ocorrência do 3º (terceiro) bloqueio da CONTA devido a ocorrência prevista no parágrafo anterior, o CLIENTE fica obrigado a sacar em sua integralidade eventual saldo remanescente e a inutilizar imediatamente o cartão, de forma que a CONTA possa ser encerrada, podendo o CLIENTE solicitar migração para outro tipo de conta, existente, por meio do processo de transferência de saldo/produtos.<br /><br />

                    Parágrafo oitavo: A responsabilidade do CLIENTE nas operações realizadas com o CARTÃO somente cessará a partir da sua inutilização, nos casos de encerramento da CONTA ou, ainda, a comunicação, devidamente formalizada e documentada, de seu extravio, furto, roubo ou destruição.<br /><br />

                    Parágrafo nono: A utilização do CARTÃO após o seu cancelamento/ destruição será considerada, para todos os efeitos, como ocorrência de fraude, ficando desde já o EMISSOR autorizado a adotar todas as medidas aplicáveis à ocorrência.<br /><br />

                    CLÁUSULA OITAVA – DISPOSIÇÕES GERAIS<br /><br />


                    a) O CLIENTE declara que as informações prestadas por ocasião da abertura da CONTA, assim como em todas as atualizações cadastrais, são expressões da verdade, autorizando o envio de seus dados aos órgãos públicos ou privados, administradores de bancos de dados;<br /><br />


                    b) O CLIENTE autoriza o EMISSOR:<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;i) a realizar os estornos necessários para corrigir lançamentos indevidos em sua conta, decorrentes de erros operacionais e/ou fraudes ,<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;intentadas por terceiros, independentemente do fato gerador da ocorrência;<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;ii) a efetivar quaisquer operações ou transações solicitadas por meio eletrônico ou por qualquer outro meio que venha a ser disponibilizado<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;pelo EMISSOR, mediante utilização de senha, e reconhece, desde já, para todos os efeitos legais, como válidas e verdadeiras as operações<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;assim realizadas.<br /><br />


                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;iii) que os seus dados cadastrais, patrimoniais e financeiros possam ser informados a qualquer das entidades da instituição financeira <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; parceira da PLANIUM BANK.<br /><br />


                    c) O CLIENTE declara que sua relação de negócios tem como propósito e natureza a realização de movimentações financeiras na CONTA aberta junto ao EMISSOR.<br /><br />


                    d) Fica eleito o Foro da Comarca da cidade sede do EMISSOR, para dirimir qualquer controvérsia decorrente do presente TERMO, renunciando ambas as partes a qualquer outro, por mais privilegiado que seja.<br /><br />


                    e) O presente TERMO, bem como quaisquer alterações – introduzindo, retirando ou modificando as disposições deste contrato – serão disponibilizadas ao CLIENTE no APLICATIVO e serão averbadas em Cartório, aplicando-se a todos os CLIENTES, presentes e futuros.<br /><br />


                    f) Fica assegurado ao CLIENTE o direito de manifestar-se contrariamente às alterações contratuais em questão, no prazo de até 15 (quinze) dias contados da referida disponibilização.<br /><br />


                    <b>PLANIUM BANK</b><br />

                    <b>CNPJ:</b> 38.314.747/0001-97<br /><br />
                    
                    `}
              fontFamily={"Nunito"}
              fontSize={"0.96rem"}
              fontWeight={"400"}
              color={"#55595f"}
              toUppercase={false}
              className="text"
            />
          </PrincipalContainer>
        }
      />
    </>
  );
};

export { O_ContentTermos };
