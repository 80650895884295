/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from "react";
import { A_Button } from "../../atomos/button";
import {
  PrincipalContainer,
  ContainerFlex,
  ContainerMenuBurguer,
} from "./styles";
import { menus } from "../../../utils/listas/menus";
import { A_Image } from "../../atomos/image";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import { Box, IconButton } from "@mui/material";
import { A_ModalComponent } from "../../atomos/modal";
import { M_Form } from "../form";
import { A_TextLink } from "../../atomos/text-link";
import { A_TextBody } from "../../atomos/text-body";

const Logo = require("../../../assets/img-logo.png");

type Anchor = "right";

const M_MenuBurguer: FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const handleClose = () => {
    setState({
      ...state,
      right: false,
    });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: "20rem", height: "100vh !important" }}
      style={{ backgroundColor: "#182029", position: "relative" }}
    >
      <List style={{ backgroundColor: "#182029", height: "100vh !important" }}>
        <ContainerFlex>
          <IconButton onClick={handleClose} className="button-close">
            <AiOutlineClose />
          </IconButton>

          <A_Image
            width="14rem"
            src={Logo}
            alt="logo-header"
            height="auto"
            className="img-logo"
          />
        </ContainerFlex>

        <ContainerFlex>
          {menus.map(menu => {
            return (
              <ContainerFlex>
                <A_TextLink
                  key={menu.id}
                  target=""
                  path={menu.path}
                  content={menu.name}
                  fontFamily={"Nunito"}
                  fontSize={""}
                  fontWeight={"500"}
                  color={"#fff"}
                  toUppercase={false}
                  decoration={false}
                  className={"link"}
                  onClick={handleClose}
                />
              </ContainerFlex>
            );
          })}

          <A_Button
            background="linear-gradient(180deg, #8062F7 51%, #2CCCD9 100%);"
            color="#fff"
            width={"9rem"}
            height={"2.5rem"}
            className="button"
            onClick={() => setIsOpenModal(true)}
          >
            <A_TextBody
              content={"Abra sua conta"}
              fontFamily={"Nunito"}
              fontSize={".8rem"}
              fontWeight={"500"}
              color={"#fff"}
              toUppercase={true}
            />
          </A_Button>

          <A_Button
            background="transparent"
            color="#fff"
            height={"2.5rem"}
            width={"9rem"}
            className="button-account-exist button"
          >
            <A_TextLink
              target="_blank"
              path="https://ib.planiumbank.com.br/"
              content={"Já tenho conta"}
              fontFamily={"Nunito"}
              fontSize={".8rem"}
              fontWeight={"500"}
              color={"#fff"}
              toUppercase={true}
              decoration={false}
            />
          </A_Button>
        </ContainerFlex>
      </List>
    </Box>
  );

  return (
    <PrincipalContainer>
      <div className="box-drawer">
        {(["right"] as Anchor[]).map(anchor => (
          <React.Fragment key={anchor}>
            <ContainerMenuBurguer>
              <a href="/#home">
                <A_Image
                  width="11rem"
                  src={Logo}
                  alt="logo-header"
                  height="auto"
                />
              </a>
              <Button onClick={toggleDrawer(anchor, true)}>
                <GiHamburgerMenu className="toggle" />
              </Button>
            </ContainerMenuBurguer>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              className="box-drawer"
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>

      <A_ModalComponent
        type="account"
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        children={
          <M_Form
            onCloseModal={() => setIsOpenModal(false)}
            title={"ABRA SUA CONTA DIGITAL"}
            isLogoExist={false}
            subtitle={
              "Preencha o formulário abaixo que nossa equipe entrará em contato agendando o dia e horário para demonstrarmos nossas soluções."
            }
            titleButton="Enviar formulário"
            request={"openAccount"}
          />
        }
      />
    </PrincipalContainer>
  );
};

export { M_MenuBurguer };
