export const MessageValidation = {
  Required: "Obrigatório",
  EmailInvalid: "E-mail inválido",
  CpfInvalid: "CPF inválido",
  CpfCadastrado: "CPF já cadastrado",
  CnpjInavalid: "CNPJ inválido",
  CepInavalid: "CEP inválido",
  TelefoneInavalid: "Telefone inválido",
  PasswordLenghtInvalid: "Mínimo 8 caracteres",
  PasswordCaseInvalid: "Deve ter ao menos uma letra maiúscula",
  PasswordNumberInvalid: "Deve ter ao menos um número",
  PasswordEqualInvalid: "As senhas não conferem",
  DateInvalid: "Data não pode ser cadastrada",
  CategoriaInvalida: "Categoria inválida",
  DateAnterior: "Data antiga",
  DateCNHAnterior: "Data Vencida",
  ExameVencido: "Exame toxicológico vencido",
  BirthDateInvalid: "Menor de idade",
  AllFieldsRequired: "Preencha os campos corretamente",
  SubmitForm: "Ocorreu um erro ao enviar formulário",
};

export const MessageSuccess = {
  SubmitForm: "Formulário enviado com sucesso!",
};
