export const menus = [
  { id: "operadora", name: "Operadora", path: "/#operadora" },
  { id: "corretora", name: "Corretora", path: "/#corretora" },
  { id: "corretor", name: "Corretor", path: "/#corretor" },
  { id: "plataformas", name: "Plataformas", path: "/#plataformas" },
  { id: "credito", name: "Crédito", path: "/#credito" },
  { id: "nossaApi", name: "Nossa API", path: "/#nossaApi" },
  { id: "naMidia", name: "Na Mídia", path: "/namidia" },
  { id: "duvidas", name: "Dúvidas", path: "/#faq" },
];
