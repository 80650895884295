import { ChangeEvent } from "react";
import InputMask from "react-input-mask";
import { TextValidator } from "react-material-ui-form-validator";

interface TextInputProps {
  mask?: any;
  type: string;
  name: string;
  value: string;
  validators?: any;
  errorMessages?: any;
  label: string;
  disabled?: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: ChangeEvent<HTMLInputElement>): void;
  onClick?(e: ChangeEvent<HTMLInputElement>): void;
  id?: string;
  onFocus?(e: ChangeEvent<HTMLInputElement>): void;
  defaultValue?: string;
}

export const TextInput = ({
  mask,
  type,
  name,
  value,
  validators,
  errorMessages,
  label,
  onChange,
  disabled,
  onBlur,
  id,
  onClick,
  onFocus,
  defaultValue,
}: TextInputProps) => {
  return (
    <>
      {mask ? (
        <>
          <InputMask
            mask={mask}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          >
            <TextValidator
              name={name}
              label={label}
              defaultValue={defaultValue}
              value={value}
              mask={mask}
              variant="outlined"
              fullWidth
              validators={validators}
              errorMessages={errorMessages}
              type={type}
              onChange={onChange}
            />
          </InputMask>
        </>
      ) : (
        <>
          <TextValidator
            name={name}
            label={label}
            id={id}
            disabled={disabled ? true : false}
            variant="outlined"
            onChange={onChange}
            fullWidth
            onClick={onClick}
            defaultValue={defaultValue}
            value={value}
            validators={validators}
            errorMessages={errorMessages}
            type={type}
          />
        </>
      )}
    </>
  );
};
