import { FC } from "react";
import { Image } from "./styles";

interface IImageProps {
  alt: string;
  width: string;
  src: any;
  height: string;
  className?: string;
}

const A_Image: FC<IImageProps> = ({ className, alt, width, src, height }) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
    />
  );
};

export { A_Image };
