/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { A_Image } from "../../atomos/image";
import { A_TextBody } from "../../atomos/text-body";
import { A_TextLink } from "../../atomos/text-link";
import { A_Title } from "../../atomos/title";
import { O_Session } from "../../organismos/section";
import { ContainerGrid, ContainerFlex, ContainerText, PrincipalContainerApi } from "./styles";

const imgApi = require("../../../assets/img-apis.jpeg");

const T_Api: FC = () => {
  return (
    <O_Session
      heigth={"43rem"}
      mobile="fit-content"
      tabled="fit-content"
      background={"#fff"}
      children={
        <PrincipalContainerApi>

          <A_Title
            title={"NOSSA API"}
            color={"#000"}
            fontFamily={"Nunito"}
            fontWeigth={"700"}
            fontSize={"2.2rem"}
            toUppercase={false}
            className={"title"}
            notPaddingTop={true}
          />
          <ContainerGrid>
            <ContainerFlex>

              <ContainerText>
                <A_TextBody
                  content={
                    "Para oferecer aos nossos clientes a oportunidade de escalar seus negócios com mais <b>agilidade</b> e <b>simplicidade</b>, disponibilizamos nossas API's para integração com seus sistemas de venda e gestão!"
                  }
                  fontFamily={"Nunito"}
                  fontSize={"1.2rem"}
                  fontWeight={"400"}
                  color={"#000"}
                  toUppercase={false}
                  className={"paragraph"}
                />
                <A_TextBody
                  content={
                    "E o mais importante: nossa <b>sofisticada tecnologia criptografada</b> de ponta a ponta combina métodos de autenticação e autorização, alinhados com os príncipios da rigorosa política de privacidade da LGPD e de acordo com as regras do Banco Central do Brasil, garantimos a qualidade e segurança robustas como nosso compromisso."
                  }
                  fontFamily={"Nunito"}
                  fontSize={"1.2rem"}
                  fontWeight={"400"}
                  color={"#000"}
                  toUppercase={false}
                  className={"paragraph"}
                />

                <div className="text-documentation">                
                  <A_TextBody
                    content={"Acesse nossa documentação."}
                    fontFamily={"Nunito"}
                    fontSize={"1.2rem"}
                    fontWeight={"500"}
                    color={"#000"}
                    toUppercase={false}
                  />

                  <A_TextLink
                    content={"Clique aqui"}
                    fontFamily={"Nunito"}
                    fontSize={"1.2rem"}
                    fontWeight={"500"}
                    color={"#5a5891"}
                    toUppercase={false}
                    decoration={true}
                    path={
                      "https://developer.planiumbank.io/planiumbank/api_docs/"
                    }
                    target={"_blank"}
                  />
                </div>
                
                <div className="text-documentation">                
                  <A_TextBody
                    content={"Não encontrou a API que precisa? Não se preocupe, criamos para você!"}
                    fontFamily={"Nunito"}
                    fontSize={"1.2rem"}
                    fontWeight={"500"}
                    color={"#000"}
                    toUppercase={false}
                  />

                  <A_TextLink
                    content={"Clique aqui"}
                    fontFamily={"Nunito"}
                    fontSize={"1.2rem"}
                    fontWeight={"500"}
                    color={"#5a5891"}
                    toUppercase={false}
                    decoration={true}
                    path={
                      "https://api.whatsapp.com/send?phone=21 99631-5558&text=Quero%20saber%20mais%20sobre%20sua%20API"
                    }
                    target={"_blank"}
                  />
               </div>


               
              </ContainerText>
            </ContainerFlex>

            <ContainerFlex>
              <A_Image
                alt={"image-api"}
                width={"33rem"}
                src={imgApi}
                height={"auto"}
                className="img-api"
              />
            </ContainerFlex>
          </ContainerGrid>
        </PrincipalContainerApi>
      }
    />
  );
};

export { T_Api };
