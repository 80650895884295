import styled from 'styled-components'

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 5rem;   


  .title {
    font-style: italic;
    width: 900px;    
  }

  .title, .text {
    text-align: center; 
  }

  @media (max-width: 900px) {
    .title, .text {      
      width: 90%;            
    }
  }
`;

export { FooterContainer }