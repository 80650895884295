import styled from 'styled-components'

const PrincipalContainer = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;  
  

  @media (min-width: 1200px) and (max-width: 1480px) {
    .menu-mobile {
      font-size: 0.78rem;
    }
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;  
  gap: .95rem;  

  .button-account-exist{
    border: 1px solid #fff;   
  }
`;

export {
  PrincipalContainer,
  ContainerFlex,
}