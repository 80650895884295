import { FC, useEffect, useState } from "react";
import { A_Image } from "../../atomos/image";
import { M_Menu } from "../../moleculas/menu";
import {
  PrincipalContainer,
  ContainerFlex,
  ContainerDesktop,
  ContainerMobile,
} from "./styles";
import { M_MenuBurguer } from "../../moleculas/menu-burguer";
const Logo = require("../../../assets/img-logo.png");

interface IHeaderProps {
  session: "pageTermos" | "default";
}

const O_Header: FC<IHeaderProps> = ({ session }) => {
  const [scroll, setScrol] = useState<any>();
  const [hiddenHeader, setHeaderHidden] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrol(window.scrollY);
    };
    if (onScroll >= scroll) {
      setHeaderHidden(true);
    } else {
      setHeaderHidden(false);
    }
    window.addEventListener("scroll", onScroll);
  }, [hiddenHeader, scroll]);

  return (
    <PrincipalContainer scroll={scroll}>
      <ContainerDesktop>
        <ContainerFlex href={session === "default" ? "/#home" : "/"}>
          <A_Image width="11rem" src={Logo} alt="logo-header" height="auto" />
        </ContainerFlex>
        <ContainerFlex>
          <M_Menu session={session} />
        </ContainerFlex>
      </ContainerDesktop>

      <ContainerMobile>
        <M_MenuBurguer />
      </ContainerMobile>
    </PrincipalContainer>
  );
};

export { O_Header };
