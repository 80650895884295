/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { A_ButtonBackToTop } from "./components/atomos/button-back-to-top";
import { GlobalStyle } from "./global/globalStyle";
import { MainRouter } from "./routers/router";

function App() {
	return (
		<Router>
			<MainRouter />
			<GlobalStyle />
			<A_ButtonBackToTop />
		</Router>
	);
}

export default App;
