import styled from "styled-components";

const PrincipalContainer = styled.div`
  padding-top: 2rem;
  max-width: 1000px;
  margin: auto;

  .text {
    line-height: 1.6rem;
  }

  @media(max-width: 700px) {  
    width: 90%;
  }
`;


export { PrincipalContainer };
