import { FC } from "react";
import { Span } from "./styles";

interface ITextProps {
  content: string;
  fontFamily: "Nunito" | "Cabin" | "Roboto";
  fontSize: string;
  fontWeight: string;
  color: string;
  toUppercase: boolean;
  className?: string;
}

const A_TextBody: FC<ITextProps> = ({
  content,
  fontFamily,
  fontSize,
  fontWeight,
  color,
  toUppercase,
  className,
}) => {
  return (
    <Span
      className={className}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      toUppercase={toUppercase}
      dangerouslySetInnerHTML={{__html: content}}
    />         
  );
};

export { A_TextBody };
