import { FC } from "react";
import { Title } from "./styles";

interface ITitleProps {
  title: string;
  color: string;
  fontFamily: "Nunito" | "Cabin" | "hero";
  fontWeigth: string;
  fontSize: string;
  toUppercase: boolean;
  className?: string;
  notPaddingTop: boolean
}

const A_Title: FC<ITitleProps> = ({
  title,
  color,
  fontFamily,
  fontSize,
  fontWeigth,
  toUppercase,
  className,
  notPaddingTop
}) => {
  return (
    <Title
      fontSize={fontSize}
      fontWeight={fontWeigth}
      fontFamily={fontFamily}
      color={color}
      notPaddingTop={notPaddingTop}
      toUppercase={toUppercase}
      className={className}
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    ></Title>
  );
};

export { A_Title };
