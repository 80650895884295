/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { A_Title } from "../../components/atomos/title";
import { M_BackgroundTitleImage } from "../../components/moleculas/background-title-image";
import { O_Header } from "../../components/organismos/header";
import { O_ContentNaMidia } from "../../components/organismos/content-na-midia";
import { T_Footer } from "../../components/templates/footer";

export const P_NaMidia: FC = () => (
  <>
    <O_Header session="default" />
    <M_BackgroundTitleImage
      sessionPageBackground="homeEndMidia"
      children={
        <A_Title
          title={"Planium Bank na Mídia"}
          color={"#fff"}
          fontFamily={"Nunito"}
          fontWeigth={"500"}
          fontSize={"1.8rem "}
          toUppercase={true}
          className={"title"}
          notPaddingTop={false}
        />
      }
    />
    <O_ContentNaMidia />
    <T_Footer />
  </>
);
