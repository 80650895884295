import styled from 'styled-components'

const ContainerAll = styled.div`
  .title {
    z-index: 9;   
  }

  @media (max-width: 700px){  
    .title {
      text-align: center;
      width: 90%;      
    }
  } 
`;

const PrincipalContainer = styled.div`  
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;  
  
  .MuiTabs-flexContainer{                   
    @media (max-width: 400px){   
      display: block;       
      max-width: 380px;     
      margin: auto;
    }
  }  

  .MuiTabs-fixed  {    
    width: 800px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    height: 8rem;          

    .MuiTab-root {      
      width: calc(100% / 3);    
      color: var(--color-question);  
    }
    
    .Mui-selected {
      color: var(--color-question-selected);  
    }    

    img {
      padding-bottom: 1rem;
    }

    .MuiTabs-indicator {
      background-color: var(--color-question-selected);  
    }

    @media (max-width: 400px){   
      height: 10rem; 
    }
  }
`;

const ContainerQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 800px;
  margin: auto; 
`;

export { PrincipalContainer, ContainerQuestions, ContainerAll }