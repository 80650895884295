import styled from 'styled-components'

interface IContainerProps {
  heigth: string;
  background: string
  mobile: any;
  tabled: any
}

const Container = styled.section<IContainerProps>`
  background: ${props => props.background};  
  height: ${props => props.heigth};
  padding-bottom: 3.5rem;

  @media (min-width: 767px) and (max-width: 1080px){
    height: ${props => props.tabled} !important;
  }

  @media (max-width: 767px) {
    height: ${props => props.mobile} !important;
  }

`

export {
  Container
}