import { FC } from "react";
import { Subtitle } from "./styles";

interface ISubtitleProps {
  title: string;
  color: string;
  fontFamily: "Nunito" | "Cabin" | "hero";
  fontWeigth: string;
  fontSize: string;
  className?: string;
}

const A_Subtitle: FC<ISubtitleProps> = ({
  title,
  color,
  fontFamily,
  fontSize,
  fontWeigth,
  className,
}) => {
  return (
    <Subtitle
      fontSize={fontSize}
      fontWeight={fontWeigth}
      fontFamily={fontFamily}
      color={color}
      className={className}
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    ></Subtitle>
  );
};

export { A_Subtitle };
