import styled from 'styled-components'

interface IIconProps {
  width: string;
  height: string;
  colorIcon?: string;
  
}

const PrincipalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;  

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;

  @media (max-width: 700px) {        
    align-items: center;
    text-align: center;
  }
`

const Icon = styled.div<IIconProps>`
  svg {
    width: ${props => props.width};
    height: ${props => props.height}; 
    color: ${props => props.colorIcon};
  } 
`

export {
  ContainerFlex,
  PrincipalContainer,
  Icon
}