/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { ContainerContent, ContainerInfos } from "./styles";
import { A_Image } from "../../components/atomos/image";
import { A_TextBody } from "../../components/atomos/text-body";
import { ContainerAll } from "./styles";
import { Link } from "react-router-dom";
import { A_TextLink } from "../../components/atomos/text-link";
const logo = require("../../assets/img-logo-cor-letra-preta.png");
const playStore = require("../../assets/img-google.png");
const appStore = require("../../assets/img-apple.png");

export const P_MessageConfirmacao: FC = () => {
  return (
    <ContainerAll>
      <ContainerContent>
        <ContainerInfos>
          <A_Image
            alt={"logo"}
            width={"15rem"}
            src={logo}
            height={""}
            className="img-logo"
          />
        </ContainerInfos>

        <A_TextBody
          content={"Suas informações foram salvas com sucesso!"}
          fontFamily={"Nunito"}
          fontSize={"1.7rem"}
          fontWeight={"700"}
          color={"#000"}
          toUppercase={false}
        />

        <A_TextBody
          content={"Para concluir seu cadastro, siga as seguintes instruções:"}
          fontFamily={"Nunito"}
          fontSize={"1rem"}
          fontWeight={"700"}
          color={"#000"}
          toUppercase={false}
        />

        <A_TextBody
          content={`
    <b>Pessoa Física:</b> Basta baixar o nosso App, clicar em “Quero me cadastrar” e preencher as informações necessárias.
    <br /><br />
    <b>Pessoa Jurídica:</b> Aguarde que iremos enviar um e-mail com os próximos passos.
  `}
          fontFamily={"Nunito"}
          fontSize={""}
          fontWeight={"400"}
          color={"#000"}
          className="text-pf"
          toUppercase={false}
        />

        <A_TextBody
          content={`Basta clicar na sua loja de aplicativos!`}
          fontFamily={"Nunito"}
          fontSize={""}
          fontWeight={"700"}
          color={"#000"}
          toUppercase={false}
        />

        <ContainerInfos>
          <Link
            to={
              "https://play.google.com/store/apps/details?id=io.cashway.planium.app"
            }
          >
            <A_Image
              alt={"logo"}
              width={"11rem"}
              src={playStore}
              height={""}
              className="img"
            />
          </Link>

          <Link
            to={
              "https://play.google.com/store/apps/details?id=io.cashway.planium.app"
            }
          >
            <A_Image
              alt={"logo"}
              width={"11rem"}
              src={appStore}
              height={""}
              className="img"
            />
          </Link>
        </ContainerInfos>

        <A_TextLink
          content={"Voltar para home"}
          fontFamily={"Nunito"}
          fontSize={"1rem"}
          fontWeight={"500"}
          color={"#535353"}
          toUppercase={false}
          decoration={false}
          path={"/"}
          target={""}
        />
      </ContainerContent>
    </ContainerAll>
  );
};
