import { FC } from "react";
import { Link } from "./styles";

interface ITextProps {
  content: string;
  fontFamily: "Nunito" | "Cabin" | "Roboto";
  fontSize: string;
  fontWeight: string;
  color: string;
  toUppercase: boolean;
  decoration: boolean;
  path: string;
  target: "_blank" | "";
  className?: string;
  onClick?: () => void;
  hoverEffect?: boolean
}

const A_TextLink: FC<ITextProps> = ({
  content,
  fontFamily,
  fontSize,
  fontWeight,
  color,
  toUppercase,
  decoration,
  path,
  target,
  className,
  onClick,
  hoverEffect = false
}) => {
  return (
    <Link
      hoverEffect={hoverEffect}
      href={path}
      target={target}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      toUppercase={toUppercase}
      decoration={decoration}
      className={className}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: content }}
    >
    </Link>
  );
};

export { A_TextLink };
