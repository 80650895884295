import { api } from "../../service/api";
import { variables } from "../variables/variables";


export const handleNaMidia = async () => {
  try {
    let url = variables.naMidia
    return await api
      .get(url)
      .then((res: any) => {
        return res;
      })
      .catch(() => {
        throw Error("Erro ao buscar informações");
      });
  } catch (error) {
    throw error;
  }
}