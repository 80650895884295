/* eslint-disable react/jsx-pascal-case */
import { FC, ReactNode } from "react";
import { A_Subtitle } from "../../atomos/subtitle";
import { A_Title } from "../../atomos/title";
import { ContainerFlex, ContainerChildren, Content } from "./styles";

interface IContainerClients {
	title: string;
	subTitle: string;
	children: ReactNode;
	colorTitle: string;
	colorText: string;
}

const M_ContainerClients: FC<IContainerClients> = ({
	title,
	subTitle,
	children,
	colorText,
	colorTitle,
}) => {
	return (
		<Content>
			<ContainerFlex>
				<A_Title
					title={title}
					color={colorTitle}
					fontFamily={"Nunito"}
					fontWeigth={"600"}
					fontSize={"2.2rem"}
					toUppercase={true}
					notPaddingTop={false}
				/>
				<A_Subtitle
					title={subTitle}
					color={colorText}
					fontFamily={"Nunito"}
					fontWeigth={"400"}
					fontSize={"1.2rem"}
				/>
			</ContainerFlex>

			<ContainerChildren>{children}</ContainerChildren>
		</Content>
	);
};

export { M_ContainerClients };
