import styled from 'styled-components'

interface IImageFile {
  imageFile: string;
}

const PrincipalContainer = styled.div<IImageFile>`
  background: no-repeat center/cover url(${props => props.imageFile});  
  width: 100vw;  

  @media (min-width: 1200px) and (max-width: 1480px) {
    max-width: 1200px !important; 
    margin: auto;           
  }    
`;

const Content = styled.div`
  max-width: 1250px;
  margin: auto;      
`

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);   
  gap: 2rem;

  @media (max-width: 900px) {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;   
  }
`

export {
  PrincipalContainer,
  Content,
  ContainerGrid  
}