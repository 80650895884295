import React, { ButtonHTMLAttributes } from "react";
import { Button } from "./styles";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	background: string;
	color: string;
	width: string;
	height: string;
}

const A_Button: React.FC<IButtonProps> = ({
	children,
	background,
	color,
	width,
	height,
	...rest
}) => (
	<Button
		{...rest}
		background={background}
		color={color}
		width={width}
		height={height}>
		{children}
	</Button>
);

export { A_Button };
