import styled from 'styled-components'

interface ISpanProps {
  fontFamily: string
  fontSize: string
  fontWeight: string
  color: string
  toUppercase: boolean;
}

const Span = styled.span<ISpanProps>`  
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  text-transform: ${props => props.toUppercase ? "uppercase" : "initial"};
`

export {
  Span
}