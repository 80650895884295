import styled from 'styled-components'

const PrincipalContainerApi = styled.div`
.title {
    width: 100%;
    text-align: center;
    margin-top: -1rem;
  }

  .text-documentation {
    display: flex;
    gap: .5rem;    
  }

  @media(max-width: 767px) {
    .text-documentation {    
      display: grid;
      gap: .5rem;
      grid-template-columns: 100%;
  }

    .title {
      margin-top: -2.5rem;
    }
  }
`

const ContainerGrid = styled.div`
  max-width: 1250px;
  margin: auto;
  display: grid;
  grid-template-columns: 60% 40%;

  align-items: center;

  @media (min-width: 1200px) and (max-width: 1500px) {
    max-width: 1160px;     
    grid-template-columns: 65% 35%;   
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;  
  }
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  gap: 2rem;  
  margin-top: 3rem;
  
  .paragraph {
    line-height: 2.5rem;
  }

  @media (max-width: 900px) {
    width: 100%;  
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;      

  @media (min-width: 1200px) and (max-width: 1500px) {
    .img-api {     
      width: 27rem;      
    }
  }

  @media (max-width: 900px) {
    padding: 0rem 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;

    .img-api {      
      width: 23rem;
      margin-top: 2rem;
      margin-left: -.9rem;
    }

    .title {
      width: 100%;      
      text-align: center;
    }
  }
`;

export {
  ContainerGrid,
  ContainerFlex,
  ContainerText,
  PrincipalContainerApi
}