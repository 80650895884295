/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from "react";
import { handleNaMidia } from "../../../utils/requests/handle-na-midia";
import { O_Session } from "../section";
import {
  PrincipalBox,
  PrincipalContainer,
  Content,
  ContainerFlex,
} from "./styles";
import { A_TextBody } from "../../atomos/text-body";
import { A_TextLink } from "../../atomos/text-link";
const background = require("../../../assets/img-background.png");

const O_ContentNaMidia: FC = () => {
  const [dadosMidia, setDadosMida] = useState([]);

  async function getNaMidia() {
    await handleNaMidia().then((response: any) => {
      if (!response.data.isError) {
        let monthName = [
          "JAN",
          "FEV",
          "MAR",
          "ABR",
          "MAI",
          "JUN",
          "JUL",
          "AGO",
          "SET",
          "OUT",
          "NOV",
          "DEZ",
        ];
        let dadosMidia: any = [];
        let dadosApi = response.data.data;        

        dadosApi.forEach((element: any) => {                            
          var midia: any;
          let attributes = element.attributes;
          let data: any = attributes.data.split("-");
          let month = Number(data[1]);
          let groupKey = `${monthName[month - 1]}/${data[0]}`;

          var dadosMidiaElement: any = dadosMidia.find(
            (data: any) => data.key === groupKey
          );

          if (dadosMidiaElement !== undefined) {
            dadosMidiaElement.data.push(attributes);
          } else {
            midia = { key: groupKey, month, data: [] };
            midia.data.push(attributes);
            dadosMidia.push(midia);
          }
        });

        dadosMidia.sort((a: any, b: any) => {
          if (a?.month > b?.month) return -1 
          if (a?.month < b?.month) return 1 

          return 0
        })

        setDadosMida(dadosMidia);
      }
    });
  }

  useEffect(() => {
    getNaMidia();
  }, []);

  return (
    <O_Session
      heigth={"auto"}
      background={background}
      children={
        <PrincipalContainer imageFile={background}>
          <Content>
            {dadosMidia.sort((a: any, b: any): any => {
              if (a?.key.split("/")[1] > b?.key.split("/")[1]) return -1 
              if (a?.key.split("/")[1] < b?.key.split("/")[1]) return 1 

              return 0
            }).map((dataMidia: any) => {                  
              return (
                <>
                  <PrincipalBox>
                    <ContainerFlex className="container-none-after">
                      <A_TextBody
                        content={dataMidia.key}
                        fontFamily={"Roboto"}
                        fontSize={".9rem"}
                        fontWeight={"600"}
                        color={"rgba(128, 98, 247, 1)"}
                        toUppercase={true}
                        className="month"
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      {dataMidia.data.map((midia: any) => {
                        return (
                          <>
                            <ContainerFlex className="container-none-after container-infos-na-midia">
                              <A_TextBody
                                content={midia.titulo}
                                fontFamily={"Roboto"}
                                fontSize={".9rem"}
                                fontWeight={"600"}
                                color={"#000"}
                                toUppercase={false}
                                className="title"
                              />
                              <A_TextLink
                                content={midia.link}
                                fontFamily={"Roboto"}
                                fontSize={".85rem"}
                                fontWeight={"400"}
                                color={""}
                                toUppercase={false}
                                decoration={false}
                                path={midia.link}
                                target={"_blank"}
                              />
                            </ContainerFlex>
                          </>
                        );
                      })}
                    </ContainerFlex>
                  </PrincipalBox>
                </>
              );
            })}
          </Content>
        </PrincipalContainer>
      }
    />
  );
};

export { O_ContentNaMidia };
