/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from "react";
import { A_Button } from "../../atomos/button";
import { A_TextLink } from "../../atomos/text-link";
import { PrincipalContainer, ContainerFlex } from "./styles";
import { menus } from "../../../utils/listas/menus";
import { A_ModalComponent } from "../../atomos/modal";
import { A_TextBody } from "../../atomos/text-body";
import { M_Form } from "../form";

interface IMenuProps {
  session: "pageTermos" | "default";
}

const M_Menu: FC<IMenuProps> = ({ session }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <PrincipalContainer>
      {session === "default" ? (
        <ContainerFlex>
          {menus.map(menu => {
            return (
              <>
                <A_TextLink
                  key={menu.id}
                  target=""
                  path={menu.path}
                  content={menu.name}
                  fontFamily={"Nunito"}
                  fontSize={".8rem"}
                  fontWeight={"500"}
                  color={"#fff"}
                  toUppercase={true}
                  decoration={false}
                  className="menu-mobile"
                  hoverEffect
                />
              </>
            );
          })}
        </ContainerFlex>
      ) : (
        <></>
      )}

      <ContainerFlex>
        <A_Button
          background="linear-gradient(180deg, #8062F7 51%, #2CCCD9 100%);"
          color="#fff"
          width={"9rem"}
          onClick={() => setIsOpenModal(true)}
          height={"2.5rem"}
        >
          <A_TextBody
            content={"Abra sua conta"}
            fontFamily={"Nunito"}
            fontSize={".8rem"}
            fontWeight={"500"}
            color={"#fff"}
            toUppercase={true}
          />
        </A_Button>

        <A_Button
          background="transparent"
          color="#fff"
          height={"2.5rem"}
          width={"9rem"}
          className="button-account-exist"
        >
          <A_TextLink
            target="_blank"
            path="https://ib.planiumbank.com.br/"
            content={"Já tenho conta"}
            fontFamily={"Nunito"}
            fontSize={".8rem"}
            fontWeight={"500"}
            color={"#fff"}
            toUppercase={true}
            decoration={false}
          />
        </A_Button>
      </ContainerFlex>

      <A_ModalComponent
        type="account"
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        children={
          <M_Form
            existPersonalData
            onCloseModal={() => setIsOpenModal(false)}
            title={"ABRA SUA CONTA DIGITAL"}
            isLogoExist={false}
            subtitle={
              "Preencha o formulário abaixo que nossa equipe entrará em contato agendando o dia e horário para demonstrarmos nossas soluções."
            }
            titleButton="Enviar formulário"
            request={"openAccount"}
          />
        }
      />
    </PrincipalContainer>
  );
};

export { M_Menu };
