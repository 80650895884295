import { FC } from "react";
import CookieConsent from "react-cookie-consent";
import { PrincipalContainer } from "./styles";
import { A_TextBody } from "../../atomos/text-body";

const M_Cookies: FC = () => {
  return (
    <PrincipalContainer>
      <CookieConsent
        location="bottom"
        buttonText="Aceitar"
        cookieName="myAwesomeCookieName2"
        expires={150}
      >
        <A_TextBody
          content={
            "Nós usamos cookies e outras tecnologias para proporcionar uma melhor experiência de navegação. Ao continuar navegando, significa que você concorda com a nossa política de privacidade."
          }
          fontFamily={"Nunito"}
          fontSize={"1rem"}
          fontWeight={"500"}
          color={"#fff"}
          toUppercase={false}
          className="text"
        />
      </CookieConsent>
    </PrincipalContainer>
  );
};

export { M_Cookies };
