import { FC, ReactNode, useMemo } from "react";
import { BackgroundTitle, Overlay } from "./styles";

const backgroundFaqEndNaMidia = require("../../../assets/img-background-faq-end-na-midia.jpg");
const backgroundTermos = require("../../../assets/img-private-policy.jpg");

interface IBackgroundTitleProps {
  children: ReactNode;
  sessionPageBackground: "homeEndMidia" | "pageTermos";
}

const M_BackgroundTitleImage: FC<IBackgroundTitleProps> = ({
  children,
  sessionPageBackground,
}) => {
  const handleBackgroundPage = useMemo(() => {
    switch (sessionPageBackground) {
      case "homeEndMidia":
        return backgroundFaqEndNaMidia;

      case "pageTermos":
        return backgroundTermos;
    }
  }, [sessionPageBackground]);
  return (
    <BackgroundTitle imageFile={handleBackgroundPage}>
      <Overlay session={sessionPageBackground} />
      {children}
    </BackgroundTitle>
  );
};

export { M_BackgroundTitleImage };
