import styled from 'styled-components'

interface IContainerHeaderProps {
  scroll: number;
}

const PrincipalContainer = styled.header<IContainerHeaderProps>`
  position: fixed;
  width: 100vw;
  z-index: 99;
  background-color: ${props =>
    props.scroll >= 50
      ? '#000206'
      : 'linear-gradient(180deg, #000206 8.75%, rgba(0, 0, 32, 0) 117.5%)'};

  opacity: ${props => (props.scroll >= 130 ? 100 : '1s')};
  transition: ${props => (props.scroll >= 130 ? '.3s' : '.1s')};
  box-shadow: ${props => (props.scroll >= 130 ? '2px 4px 20px 1px rgb(0, 0, 0, 0.5);' : '#000')};
  transition: .3s;
  height: 6.2rem;       
`;

const ContainerFlex = styled.a`
  margin: .3rem 3rem;  
`

const ContainerDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 900px) {
    display: none;
  }
`

const ContainerMobile = styled.div`
  display: none;
  @media(max-width: 900px) {
    display: block;
  }
`

export {
  PrincipalContainer,
  ContainerFlex,
  ContainerDesktop,
  ContainerMobile
}