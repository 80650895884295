import styled from 'styled-components'

interface IImageFile {
  imageFile: string;
}

const ContainerOne = styled.div<IImageFile>`
  background: no-repeat center/cover url(${props => props.imageFile});  
  width: 100vw;
  height: 620px; 
  
  @media (min-width: 767px) and (max-width: 1480px) {    
    height: 570px; 
  }
`;

const ContainerTwo = styled.div<IImageFile>`
  background: no-repeat center/cover url(${props => props.imageFile});  
  width: 100vw;
  height: 600px; 
  
  @media (min-width: 767px) and (max-width: 1480px) {    
    height: 530px;     
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  max-width: 1200px;
  margin: auto;
  height: 690px;
  align-items: center;

  .container-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.99rem;    
  }

  @media (min-width: 1200px) and (max-width: 1480px) {
    max-width: 1084px;    
    margin: auto;
  }

  @media (max-width: 700px) {
    .container-subtitle {
      text-align: center;
      margin-left: -.8rem;
      margin-bottom: -2.5rem;
    }
  }  
`

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .title{
    text-align: center;
    line-height: 2.2rem;
    width: 600px;
    margin-top: 7rem;    
    margin-left: .25rem;
  }

  .iframe-home {
    width: 35rem;
    height: 19rem;        
    margin-left: 2.7rem;
    margin-top: .4rem;    
  }  
  

  @media (min-width: 767px) and (max-width: 1024px) {        
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 0rem 1rem;
    
    .title{      
      width: 70%;
      margin-top: 13.5rem;        
    }

    .image-woman {
      display: none;
    }
   
    .iframe-home {      
      height: 19rem;             
      margin-top: 1rem;    
      margin-left: -.1rem;   
    }  

    .container-subtitle {
      margin: 0;
    }

    
  }

  @media (max-width: 767px) {    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 0rem 1rem;

    .title{
      text-align: center; 
      margin-left: 0rem;  
      margin-top: 15rem;  
      width: 100%;  
      font-size: 1.4rem;  
    }

    .subtitle {
      font-size: 1.2rem;  
      margin-bottom: 1.5rem !important;
    }

    .image-woman {
      display: none;
    }

    .iframe-home {
      width: 100%;      
      margin-left: 0rem;    
    }
  }
  
`

const Line = styled.div`
  border: 2px solid #b8b8b8;
`

export {
  ContainerOne,
  ContainerTwo,
  Content,
  Line,
  ContainerFlex
}