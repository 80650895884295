import styled from 'styled-components'

interface IContainerCNPJProps {
  open: boolean
}

interface ILogoProps {
  logo: string
}

const PrincipalContainer = styled.div<IContainerCNPJProps>`
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;   
  }

  .icon-close {
    position: fixed;
    top: 1rem;    
    z-index: 9999999 !important;
    right: 1rem;
    background: #fff;
    border-radius: 50px;

    :hover {
      background: #fff;
    }
  }

  .termosPoliticaProvacidade {
    margin-top: ${props => props.open ? "" : "-.4rem"}  ;       
  }
`;

const ContainerLogo = styled.div<ILogoProps>`
  display: flex;
  margin-top: ${props => props.logo !== "boletos" ? "-1.8rem" : "1rem"}; 
  margin-bottom: ${props => props.logo !== "boletos" ? "auto" : "1.8rem"}; 
  justify-content: center;

  img {
    width: ${props => props.logo !== "boletos" ? "15rem" : "15rem"};   
  }
`;

const HeaderForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  span {
    text-align: center;
  }

  .title {
    letter-spacing: 0.3em;
  }
`;

const ContainerAction = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  button {
    box-shadow: 0px 6px 6px rgb(0 0 0 / 20%);
    font-size: 1rem;
  }
`

const ContainerCheck = styled.div`
  margin-left: -.6rem;  
  
  span {
    font-size: .95rem;
    font-family: "Roboto";    
  }

  .MuiCheckbox-root {
    padding-right: .5rem;
  }

  a {
    text-decoration: none;
    color: blue;
  }
`;

const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export {
  PrincipalContainer,
  HeaderForm,
  ContainerAction,
  ContainerCheck,
  ContainerLogo,
  ContainerLoading
}