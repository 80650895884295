import { api } from "../../service/api";
import { variables } from "../variables/variables";


export const handleFaqPf = async () => {
  try {
    let url = variables.faq.pf
    return await api
      .get(url)
      .then((res: any) => {
        if (res.data.isError) throw new Error("Erro ao buscar informações")
        return res.data.data.attributes.perguntarespostas.data;
      })
      .catch(() => {
        throw Error("Erro ao buscar informações");
      });
  } catch (error) {
    throw error;
  }
}


export const handleFaqPj = async () => {
  try {
    let url = variables.faq.pj
    return await api
      .get(url)
      .then((res: any) => {
        if (res.data.isError) throw new Error("Erro ao buscar informações")
        return res.data.data.attributes.perguntarespostas.data;;
      })
      .catch(() => {
        throw Error("Erro ao buscar informações");
      });
  } catch (error) {
    throw error;
  }
}


export const handleFaqServicoProdutos = async () => {
  try {
    let url = variables.faq.produtosServicos
    return await api
      .get(url)
      .then((res: any) => {
        if (res.data.isError) throw new Error("Erro ao buscar informações")
        return res.data.data.attributes.perguntarespostas.data;;
      })
      .catch(() => {
        throw Error("Erro ao buscar informações");
      });
  } catch (error) {
    throw error;
  }
}