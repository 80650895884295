import styled from 'styled-components'

interface ITitleProps {
  fontFamily: string
  fontSize: string
  fontWeight: string
  color: string
  toUppercase: boolean;
  notPaddingTop: boolean;
}

const Title = styled.h1<ITitleProps>`
  @font-face {
    font-family: "hero";
    src: url("../fonts/HeroLight-Regular.woff") format("woff"),
      url("../fonts/HeroLight-Regular.woff") format("woff2");
  }

  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  text-transform: ${props => props.toUppercase ? "uppercase" : "initial"};
  padding-top: ${props => props.notPaddingTop ? "2rem;" : " 4rem;"};
`

export {
  Title
}