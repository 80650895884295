/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { ContainerFlex, PrincipalContainer, Icon } from "./styles";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { A_TextLink } from "../../atomos/text-link";

interface IIconProps {
	title: string;
	path: string;
	colorTitle: string;
	colorText: string;
	heightIcon: string;
	widthIcon: string;
	fontSizeTitle: string;
	icon: "facebook" | "instagram" | "twitter" | "youtube" | null;
	colorIcon: string;
}

const M_Footer: FC<IIconProps> = ({
	icon,
	title,
	path,
	colorTitle,
	widthIcon,
	fontSizeTitle,
	heightIcon,
	colorIcon,
}) => {
	const handleIconRedeSocial = () => {
		switch (icon) {
			case "facebook":
				return <FaFacebook />;

			case "instagram":
				return <FaInstagram />;

			case "twitter":
				return <FaTwitter />;

			case "youtube":
				return <FaYoutube />;

			default:
				break;
		}
	};

	return (
		<PrincipalContainer>
			{icon === null ? (
				<></>
			) : (
				<Icon height={heightIcon} width={widthIcon} colorIcon={colorIcon}>
					{handleIconRedeSocial()}
				</Icon>
			)}
			<ContainerFlex>
				<A_TextLink
					content={title}
					fontFamily={"Nunito"}
					fontSize={fontSizeTitle}
					fontWeight={"400"}
					color={colorTitle}
					toUppercase={false}
					decoration={false}
					path={path}
					target={"_blank"}
				/>
			</ContainerFlex>
		</PrincipalContainer>
	);
};

export { M_Footer };
