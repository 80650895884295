import styled from 'styled-components'

interface ILinkProps {
  fontFamily: string
  fontSize: string
  fontWeight: string
  color: string
  toUppercase: boolean;
  decoration: boolean;
  hoverEffect: boolean
}

const Link = styled.a<ILinkProps>`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  text-transform: ${props => props.toUppercase ? "uppercase" : "initial"};
  text-decoration: ${props => props.decoration ? "underline" : "none"};
  position: relative;
  transition: color .2s;  
  
  :after {
    content: '';
    width: 0%;
    height: 1px;
    background: #fff;
    position: absolute;  
    left: 0;
    bottom: -1.3rem;
    transition: width .2s;
    opacity: ${props => props.hoverEffect ? "1" : '0'};
  }

  :hover {
    :after {
      width: 100%;
    }
  }
`

export {
  Link
}