const baseUrl = "/categorias";

export const variables = {
  faq: {
    pf: `${baseUrl}/1?populate=%2A`,
    pj: `${baseUrl}/2?populate=%2A`,
    produtosServicos: `${baseUrl}/3?populate=%2A`,
  },
  account: "/abrasuacontas",
  solicitacaoDemonstracao: "/solicitedemonstracaos",
  naMidia: "/namidias"
}