import styled from 'styled-components';

interface IImageFile {
  imageFile: string;
}

const PrincipalContainerCreditos = styled.div<IImageFile>`
  background: no-repeat center/cover url(${props => props.imageFile});  
  width: 100vw; 
  border-bottom: 2px solid #cbcbcb;
  padding-bottom: 2rem;
  
 
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 1rem;   
    margin-top: 3.5rem;

    img {
      margin-bottom: 1.5rem;
    }
  }

  .title {
    width: 100%;
    text-align: center;
  }  

  .button-action {
    margin-top: 2rem;
  }

  @media(max-width: 1024px) {
    max-width: 1200px !important; 
    margin: auto;      
     
    .container {
      margin-bottom: 0;

      img {
        width: 21.5rem;
    }
    }

    .subtitle {
      width: 90%;
      margin-bottom: 2.5rem;        
      text-align: center;
    }

    .button-action {
      height: 4rem;
      width: 18rem;

      span, p {
        font-size: 1.1rem;
      }
    }
  }
  
`

const ContainerGrid = styled.div`
  max-width: 1250px;
  margin: auto;
  display: grid;
  grid-template-columns: 60% 40%;

  align-items: center;

  @media (min-width: 1200px) and (max-width: 1500px) {
    max-width: 1160px;     
    grid-template-columns: 65% 35%;   
  }

  @media (min-width: 790px) and (max-width: 1024px){
    display: flex;  
    flex-direction: column;
  }

  @media (max-width: 790px) {
    display: flex;  
    flex-direction: column;
  }
`;

const ContainerBenefits = styled.div`  
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 7px;

  > span {
    margin-bottom: 10px;    
  }

  @media (max-width: 790px) {
    margin-top: -1rem !important;

    .box-benefits {
      flex-direction: row  !important;
      justify-content: start;
          
      span, p {
        text-align: start;
      }
    }
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;      
  width: 90%;

  .img-credito {        
    margin-bottom: -2rem;
  }

  @media (max-width: 900px) {
    
    padding: 0rem 1rem;
    width: 100%;

    justify-content: center;
    align-items: center;
    
    .img-credito {      
      display: none;      
    }

    .title {
      width: 100%;
      text-align: center;
    }
  }

  @media (max-width: 720px) {  
    .img-credito {      
      display: none;
    }   
  }
`;

export {
  ContainerBenefits, ContainerFlex, ContainerGrid, PrincipalContainerCreditos
};
