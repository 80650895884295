import styled from "styled-components";

export const ContainerAll = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text-pf {
    max-width: 600px;
    margin: auto;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
`;

export const ContainerInfos = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  .img-logo, .img {
    margin: 1.5rem auto;
  }
`;