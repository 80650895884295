/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { M_BoxInfo } from "../../moleculas/box-info";
import { M_ContainerClients } from "../../moleculas/container-session-clients";
import { M_FooterReferenceClients } from "../../moleculas/footer-reference-clients";
import { O_Session } from "../../organismos/section";
import { PrincipalContainer, ContainerGrid } from "./styles";
const iconBoletos = require("../../../assets/img-icon-boletos.png");
const iconInteracao = require("../../../assets/img-icon-interacao.png");
const iconParceiros = require("../../../assets/img-icon-parceiros.png");

const T_Corretor: FC = () => {
  return (
    <>
      <O_Session
        heigth={"fit-content"}
        mobile={"68.5rem"}
        tabled="49rem"
        background={"#fff"}
        children={
          <PrincipalContainer>
            <M_ContainerClients
              title={"Corretor"}
              colorText={"#000"}
              colorTitle={"#000"}
              subTitle={"Centralize seus recebimentos"}
              children={
                <>
                  <ContainerGrid>
                    <M_BoxInfo
                      title={"Angariação"}
                      text={
                        "Cobrança da Taxa de Angariação por PIX, boleto ou cartão integrada ao processo de vendas."
                      }
                      colorTitle={"#55595F"}
                      colorText={"#000"}
                      widthIcon={"4rem"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      heightIcon={"3.5rem"}
                      image={iconBoletos}
                    />

                    <M_BoxInfo
                      title={"Recebimentos Centralizadora"}
                      text={
                        "Conta específica para recebimento e pagamento de Prêmios, Agenciamentos e Comissões. Sem custo de transferência."
                      }
                      colorTitle={"#55595F"}
                      colorText={"#000"}
                      widthIcon={"4rem"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      heightIcon={"3.5rem"}
                      image={iconParceiros}
                    />

                    <M_BoxInfo
                      title={"Adiantamentos"}
                      text={
                        "Adiantamentos de recebíveis com base no histórico de venda, da operadora e da corretora."
                      }
                      colorTitle={"#55595F"}
                      colorText={"#000"}
                      widthIcon={"4rem"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      heightIcon={"3.5rem"}
                      image={iconInteracao}
                    />
                  </ContainerGrid>
                  <M_FooterReferenceClients
                    title={
                      '"Agora, com o Planium Bank integrado ao sistema de vendas, não preciso mais verificar as regras de taxa de angariação a cada proposta e meu cliente fica confortável por receber a cobrança direto do sistema de vendas."'
                    }
                    text={"Corretor Top 10 no sistema de vendas Planium."}
                    colorTitle={"#000"}
                    colorText={"#000"}
                  />
                </>
              }
            />
          </PrincipalContainer>
        }
      />
    </>
  );
};

export { T_Corretor };
