import { api } from "../../service/api";
import { variables } from "../variables/variables";

export const handleAberturaDeConta = async (payload: any) => {
  try {
    let url = variables.account
    return await api
      .post(url, { data: payload })
      .then((res: any) => {
        return res.data;
      })
      .catch((error: any) => {
        throw Error(error);
      });
  } catch (error) {
    throw error;
  }
}