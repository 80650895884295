/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from "react";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { A_Image } from "../../atomos/image";
import { O_Session } from "../../organismos/section";
import {
  PrincipalContainer,
  ContainerFlex,
  ContainerGrid,
  ContainerDesktop,
  ContainerMobile,
  ContainerBox,
  ContainerFlexInfoBox,
} from "./styles";
import { M_ContainerSlides } from "../../moleculas/container-session-slides";
import { A_TextBody } from "../../atomos/text-body";
import { A_Button } from "../../atomos/button";
import { M_BoxInfo } from "../../moleculas/box-info";
import { A_Title } from "../../atomos/title";
import { A_ModalComponent } from "../../atomos/modal";
import { M_Form } from "../../moleculas/form";

const checkIcon = require("../../../assets/verificar-branco.png");
const checkIconBlack = require("../../../assets/verificar.png");
const logoPremia = require("../../../assets/img-logo-premia.png");
const logoAntecipa = require("../../../assets/img-logo-antecipa.png");
const logoAntecipaBranca = require("../../../assets/img-logo-antecipa-branca.png");
const logoBoletos = require("../../../assets/img-logo-boleto.png");
const imgSessionPremia = require("../../../assets/img-session-plataformas-premia.png");
const imgSessionAntecipa = require("../../../assets/img-session-plataformas-antecipa.png");
const imgSessionBoleto = require("../../../assets/img-session-boletos-new.png");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const T_Plataformas: FC = () => {
  const [isOpenModalPremia, setIsOPenModalPremia] = useState(false);
  const [isOpenModalAntecipa, setIsOpenModalAntecipa] = useState(false);
  const [isOpenModalBoletos, setIsOpenModalBoletos] = useState(false);
  const [value, setValue] = useState(0);

  const handleClickOpenModalPremia = () => {
    setIsOPenModalPremia(true);
  };

  const handleClickOpenModalAntecipa = () => {
    setIsOpenModalAntecipa(true);
  };

  const handleClickOpenModalBoletos = () => {
    setIsOpenModalBoletos(true);
  };

  const handleClickCloseModalPremia = () => {
    setIsOPenModalPremia(false);
  };

  const handleClickCloseModalAntecipa = () => {
    setIsOpenModalAntecipa(false);
  };

  const handleClickCloseModalBoletos = () => {
    setIsOpenModalBoletos(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    document.getElementsByClassName("MuiTabs-indicator")[0].className = document
      .getElementsByClassName("MuiTabs-indicator")[0]
      .className.replace("tab1", "")
      .replace("tab2", "")
      .replace("tab3", "");

    document.getElementsByClassName(
      "MuiTabs-indicator"
    )[0].className += ` tab${newValue}`;

    setValue(newValue);
  };

  return (
    <PrincipalContainer id="plataformas">
      <ContainerDesktop>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              icon={
                <A_Image
                  alt={"logo-premia"}
                  width={"22rem"}
                  src={logoPremia}
                  height={"auto"}
                  className="image"
                />
              }
              {...a11yProps(0)}
            />
            <Tab
              icon={
                <A_Image
                  alt={"logo-premia"}
                  width={"22rem"}
                  src={logoAntecipa}
                  height={"auto"}
                  className="image"
                />
              }
              {...a11yProps(1)}
            />
            <Tab
              icon={
                <A_Image
                  alt={"logo-premia"}
                  width={"22rem"}
                  src={logoBoletos}
                  height={"auto"}
                  className="image"
                />
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <O_Session
            heigth={"41rem"}
            className="session-slide session-premia"
            background={"#2cccd9"}
            children={
              <M_ContainerSlides
                title={
                  `Gerencie suas campanhas de incentivo de forma digital, com <br />entrega de prêmios automatizada e integrada à plataforma de vendas`
                }
                colorTitle={"#000"}
                children={
                  <>
                    <ContainerGrid aplicarMargin>
                      <ContainerFlex className="container premia-container">
                        <A_TextBody
                          content={"Como funciona?"}
                          fontFamily={"Nunito"}
                          fontSize={"1.5rem"}
                          fontWeight={"700"}
                          color={"#0b2d48"}
                          toUppercase={false}
                          className={"como-funciona"}
                        />

                        <ContainerFlex>
                          <ContainerBox style={{ marginTop: ".6rem" }}>
                            <ContainerFlex>
                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Catálogo de prêmios, produtos,
                                  vouchers, experiências, financeiro
                                  e viagens;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Recebimento em dinheiro na conta do Planium Bank;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Campanha de incentivo: Vendeu-Ganhou, Ranking, Meta e entre outros;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Motor de cálculo, integração de dados,
                                  notificação, dashboards, governança,
                                  LGPD;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Treinamento: Vídeo + Quiz;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Envie de forma segmentada quizzes para serem respondidos
                                  após um vídeo. Premie somente os vendedores que
                                  responderem primeiro;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Dashboards gerenciais;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />


                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Acompanhe em tempo real o engajamento dos vendedores:
                                  performance de aceites, quantidade de vendas, volume de
                                  resgates;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Comunicação com a ponta: notificação
                                  nativa push, notificação in-app, e-mail, SMS, chat de atendimento.`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />
                            </ContainerFlex>
                          </ContainerBox>
                        </ContainerFlex>

                        <A_Button
                          background={"#0b2d48 "}
                          color={"#fff"}
                          width={"15rem"}
                          className={"container-button unic-button-premia"}
                          height={"3rem"}
                          onClick={handleClickOpenModalPremia}
                        >
                          <A_TextBody
                            content={"Solicitar uma demonstração"}
                            fontFamily={"Nunito"}
                            fontSize={"1rem"}
                            fontWeight={"700"}
                            color={"#fff"}
                            toUppercase={false}
                          />
                        </A_Button>
                      </ContainerFlex>

                      <ContainerFlex>
                        <A_Image
                          alt={"img-session-premia"}
                          width={"51rem"}
                          src={imgSessionPremia}
                          height={"auto"}
                          className="img-session-premia"
                        />
                      </ContainerFlex>
                    </ContainerGrid>
                  </>
                }
              />
            }
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <O_Session
            heigth={"45.5rem"}
            className="session-slide"
            background={"#0b2d48"}
            children={
              <M_ContainerSlides
                title={
                  `Melhore a <B>sustentabilidade</b> do seu parceiro de <b>vendas</b> mantendo <br /> uma operação <b>financeira saudável.</b>`
                }
                subtitle="Criamos um ambiente de negociação de recebíveis entre seus <br > parceiros comerciais e bancos onde todos saem ganhando."
                colorTitle={"#fff"}
                children={
                  <>
                    <ContainerGrid aplicarMargin>
                      <ContainerFlex className="container antecipa-container">
                        <A_TextBody
                          content={"O que você ganha:"}
                          fontFamily={"Nunito"}
                          fontSize={"1.5rem"}
                          fontWeight={"700"}
                          color={"#fff"}
                          toUppercase={false}
                          className={"como-funciona"}
                        />

                        <ContainerFlex>
                          <ContainerBox style={{ marginTop: ".6rem" }}>
                            <ContainerFlex>
                              <M_BoxInfo
                                image={checkIcon}
                                title={""}
                                text={`Fidelização do seu canal de venda;`}
                                colorTitle={""}
                                colorText={"#fff"}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={"1.08rem"}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIcon}
                                title={""}
                                text={`Melhores taxas e desconto dinâmico por dia;`}
                                colorTitle={""}
                                colorText={"#fff"}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={"1.08rem"}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIcon}
                                title={""}
                                text={`Formalização do processo de antecipação sem burocracia.`}
                                colorTitle={""}
                                colorText={"#fff"}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={"1.08rem"}
                                classNameBoxAll="container-message"
                              />
                            </ContainerFlex>

                            <ContainerFlex>
                              <A_TextBody
                                content={"O que seu parceiro ganha:"}
                                fontFamily={"Nunito"}
                                fontSize={"1.5rem"}
                                fontWeight={"700"}
                                color={"#fff"}
                                toUppercase={false}
                                className={"como-funciona"}
                              />

                              <M_BoxInfo
                                image={checkIcon}
                                title={""}
                                text={`Antecipação do recebível quando e onde quiser;`}
                                colorTitle={""}
                                colorText={"#fff"}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={"1.08rem"}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIcon}
                                title={""}
                                text={`Redução dos prazos de recebimento dos seus parceiros;`}
                                colorTitle={""}
                                colorText={"#fff"}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={"1.08rem"}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIcon}
                                title={""}
                                text={`Sem análise de crédito.`}
                                colorTitle={""}
                                colorText={"#fff"}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={"1.08rem"}
                                classNameBoxAll="container-message"
                              />
                            </ContainerFlex>
                          </ContainerBox>
                        </ContainerFlex>

                        <A_Button
                          background={"#2cccd9 "}
                          color={"#fff"}
                          width={"15rem"}
                          className={"container-button unic-button"}
                          height={"3rem"}
                          onClick={handleClickOpenModalAntecipa}
                        >
                          <A_TextBody
                            content={"Solicitar uma demonstração"}
                            fontFamily={"Nunito"}
                            fontSize={"1rem"}
                            fontWeight={"700"}
                            color={"#fff"}
                            toUppercase={false}
                          />
                        </A_Button>
                      </ContainerFlex>

                      <ContainerFlex style={{ paddingTop: "3rem" }} className="unic-button">
                        <A_Image
                          alt={"img-session-antecipa"}
                          width={"51rem"}
                          src={imgSessionAntecipa}
                          height={"auto"}
                          className="img-session-antecipa"
                        />
                      </ContainerFlex>
                    </ContainerGrid>
                  </>
                }
              />
            }
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <O_Session
            heigth={"43rem"}
            className="session-slide session-boletos"
            background={"#2cccd9"}
            children={
              <M_ContainerSlides
                title={
                  `Uma plataforma exclusiva para você cuidar dos recebíveis da sua empresa!`
                }
                subtitle="Gerencie sua cobrança com relatórios e dashboards  <br /> intuitivos em um ambiente criado para facilitar a sua vida."
                colorTitle={"#000"}
                children={
                  <>
                    <ContainerGrid aplicarMargin>
                      <ContainerFlex className="container">
                        <A_TextBody
                          content={"Como funciona?"}
                          fontFamily={"Nunito"}
                          fontSize={"1.5rem"}
                          fontWeight={"700"}
                          color={"#0b2d48"}
                          toUppercase={false}
                          className={"como-funciona"}
                        />

                        <ContainerFlex>
                          <ContainerBox style={{ marginTop: ".6rem" }}>
                            <ContainerFlex>
                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Relatórios e dashboards gerenciais;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Emissão de boletos via arquivo CNAB;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Integração por API's;`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />

                              <M_BoxInfo
                                image={checkIconBlack}
                                title={""}
                                text={`Registro de boleto em até 15 minutos.`}
                                colorTitle={""}
                                colorText={""}
                                heightIcon={"1.5rem"}
                                widthIcon={"auto"}
                                fontSizeTitle={""}
                                fontSizeText={""}
                                classNameBoxAll="container-message"
                              />
                            </ContainerFlex>
                          </ContainerBox>
                        </ContainerFlex>

                        <A_Button
                          background={"#0b2d48 "}
                          color={"#fff"}
                          width={"15rem"}
                          className={"container-button unic-button-boleto"}
                          height={"3rem"}
                          onClick={handleClickOpenModalBoletos}
                        >
                          <A_TextBody
                            content={"Solicitar uma demonstração"}
                            fontFamily={"Nunito"}
                            fontSize={"1rem"}
                            fontWeight={"700"}
                            color={"#fff"}
                            toUppercase={false}
                          />
                        </A_Button>
                      </ContainerFlex>

                      <ContainerFlex>
                        <A_Image
                          alt={"img-session-boleto"}
                          width={"31rem"}
                          src={imgSessionBoleto}
                          height={"auto"}
                          className="img-session-boleto"
                        />
                      </ContainerFlex>
                    </ContainerGrid>
                  </>
                }
              />
            }
          />
        </TabPanel>

        {/* -------------- */}
      </ContainerDesktop>

      <ContainerMobile>
        <O_Session
          heigth={""}
          mobile={"fit-content"}
          background={"#2CCCD9"}
          children={
            <ContainerGrid>
              <A_Image
                alt={"logo-premia"}
                width={"21.5rem"}
                src={logoPremia}
                height={"auto"}
              />
              <ContainerMobile className="container-mobile">
                <A_Title
                  title={
                    "Gerencie suas campanhas de incentivo de forma digital e com entrega de prêmios automatizada e integrada à plataforma de vendas"
                  }
                  color={"#fff"}
                  fontFamily={"Nunito"}
                  fontWeigth={"400"}
                  fontSize={"1.7rem"}
                  toUppercase={false}
                  className="title-principal"
                  notPaddingTop={false}
                />

                <ContainerBox className="box-mobile-infos">
                  <ContainerFlexInfoBox>
                    <A_TextBody
                      content={"Como funciona?"}
                      fontFamily={"Nunito"}
                      fontSize={"1.4rem"}
                      fontWeight={"600"}
                      color={"#0b2d48"}
                      toUppercase={false}
                      className="como-funciona"
                    />
                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Catálogo de prêmios, produtos, vouchers, experiências, financeiro e viagens.`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Recebimento em dinheiro na conta do Planium Bank;`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Campanha de incentivo: Vendeu-Ganhou, Ranking, Meta e entre outros;`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Motor de cálculo, integração de dados, notificação, dashboards, governança, LGPD;`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Treinamento: Vídeo + Quiz;`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Envie de forma segmentada quizzes para serem respondidos após um vídeo. Premie somente os vendedores que responderem primeiro;`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Dashboards gerenciais;`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Acompanhe em tempo real o engajamento dos vendedores: performance de aceites, quantidade de vendas, volume de resgates;`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />


                    <M_BoxInfo
                      image={checkIconBlack}
                      title={""}
                      text={`Comunicação com a ponta: notificação nativa push, notificação in-app, e-mail, SMS, chat de atendimento.`}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />     </ContainerFlexInfoBox>
                </ContainerBox>

                <ContainerMobile className="container-button">
                  <A_Button
                    background="#0b2d48"
                    color="#fff"
                    height={"4rem"}
                    onClick={handleClickOpenModalPremia}
                    width={"18rem"}
                  >
                    <A_TextBody
                      content={"Solicite uma demonstração"}
                      fontFamily={"Nunito"}
                      fontSize={"1.1rem"}
                      fontWeight={"600"}
                      color={"#fff"}
                      toUppercase={false}
                    />
                  </A_Button>
                </ContainerMobile>
              </ContainerMobile>
            </ContainerGrid>
          }
        />

        <O_Session
          heigth={""}
          mobile={"fit-content"}
          background={"#0b2d48"}
          children={
            <ContainerGrid>
              <A_Image
                alt={"logo-premia"}
                width={"21.5rem"}
                src={logoAntecipaBranca}
                height={"auto"}
              />
              <ContainerMobile className="container-mobile">
                <A_Title
                  title={
                    "Melhore a sustentabilidade do seu parceiro de vendas mantendo uma operação financeira saudável"
                  }
                  color={"#fff"}
                  fontFamily={"Nunito"}
                  fontWeigth={"400"}
                  fontSize={"1.7rem"}
                  toUppercase={false}
                  className="title-principal"
                  notPaddingTop={false}
                />

                <A_Title
                  title={
                    "Criamos um ambiente de negociação de recebíveis entre seus parceiros comerciais e bancos onde todos saem ganhando."
                  }
                  color={"#fff"}
                  fontFamily={"Nunito"}
                  fontWeigth={"400"}
                  fontSize={"1.25rem"}
                  toUppercase={false}
                  className="subtitle-principal"
                  notPaddingTop={false}
                />

                <ContainerBox className="box-mobile-infos">
                  <ContainerFlexInfoBox>
                    <A_TextBody
                      content={"O que você ganha"}
                      fontFamily={"Nunito"}
                      fontSize={"1.4rem"}
                      fontWeight={"600"}
                      className="como-funciona-antecipa"
                      color={"#fff"}
                      toUppercase={false}
                    />
                    <M_BoxInfo
                      image={checkIcon}
                      title={""}
                      text={`Fidelização do seu canal de venda;`}
                      colorTitle={""}
                      colorText={"#fff"}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIcon}
                      title={""}
                      text={`Redução dos prazos de recebimento dos seus parceiros;`}
                      colorTitle={""}
                      colorText={"#fff"}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIcon}
                      title={""}
                      text={`Formalização do processo de antecipação sem burocracia.`}
                      colorTitle={""}
                      colorText={"#fff"}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                  </ContainerFlexInfoBox>
                </ContainerBox>

                <ContainerBox className="box-mobile-infos">
                  <ContainerFlexInfoBox className="antecipa">
                    <A_TextBody
                      content={"O que seu parceiro ganha:"}
                      fontFamily={"Nunito"}
                      fontSize={"1.4rem"}
                      fontWeight={"600"}
                      color={"#fff"}
                      toUppercase={false}
                    />

                    <M_BoxInfo
                      image={checkIcon}
                      title={""}
                      text={`Antecipação do recebível quando e onde quiser;`}
                      colorTitle={""}
                      colorText={"#fff"}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIcon}
                      title={""}
                      text={`Melhores taxas e desconto dinâmico por dia;`}
                      colorTitle={""}
                      colorText={"#fff"}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />

                    <M_BoxInfo
                      image={checkIcon}
                      title={""}
                      text={`Sem análise de crédito;`}
                      colorTitle={""}
                      colorText={"#fff"}
                      heightIcon={"1.5rem"}
                      widthIcon={"auto"}
                      fontSizeTitle={""}
                      fontSizeText={"1.08rem"}
                      classNameBoxAll="container-box-mobile"
                    />
                  </ContainerFlexInfoBox>
                </ContainerBox>
                <ContainerMobile className="container-button">
                  <A_Button
                    background="#2cccd9"
                    color="#fff"
                    height={"4rem"}
                    onClick={handleClickOpenModalAntecipa}
                    width={"18rem"}
                  >
                    <A_TextBody
                      content={"Solicite uma demonstração"}
                      fontFamily={"Nunito"}
                      fontSize={"1.1rem"}
                      fontWeight={"600"}
                      color={"#fff"}
                      toUppercase={false}
                    />
                  </A_Button>
                </ContainerMobile>
              </ContainerMobile>
            </ContainerGrid>
          }
        />

        <O_Session
          heigth={""}
          mobile={"fit-content"}
          background={"#2CCCD9"}
          children={
            <ContainerGrid>
              <A_Image
                alt={"logo-premia"}
                width={"21.5rem"}
                src={logoBoletos}
                height={"auto"}
                className="img-boletos"
              />
              <ContainerMobile className="container-mobile">
                <A_Title
                  title={
                    "Uma plataforma exclusiva para você cuidar dos recebíveis da sua empresa!"
                  }
                  color={"#fff"}
                  fontFamily={"Nunito"}
                  fontWeigth={"400"}
                  fontSize={"1.7rem"}
                  toUppercase={false}
                  className="title title-principal"
                  notPaddingTop={false}
                />

                <A_Title
                  title={
                    "Gerencie sua cobrança com relatórios e dashboards intuitivos em um ambiente criado para facilitar a sua vida."
                  }
                  color={"#fff"}
                  fontFamily={"Nunito"}
                  fontWeigth={"400"}
                  fontSize={"1.25rem"}
                  toUppercase={false}
                  className="subtitle-principal"
                  notPaddingTop={false}
                />

                <ContainerBox className="box-mobile-infos">
                  <ContainerFlexInfoBox>
                    <A_TextBody
                      content={"Como funciona?"}
                      fontFamily={"Nunito"}
                      fontSize={"1.4rem"}
                      fontWeight={"600"}
                      color={"#0b2d48"}
                      toUppercase={false}
                      className="como-funciona-boletos"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={"Relatórios e dashboards gerenciais;"}
                      text={``}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"0px"}
                      widthIcon={"auto"}
                      fontSizeTitle={"1.1rem"}
                      fontSizeText={""}
                      classNameBoxAll="box-boletos-textos unic"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={"Emissão de boletos via arquivo CNAB;"}
                      text={``}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"0px"}
                      widthIcon={"auto"}
                      fontSizeTitle={"1.1rem"}
                      fontSizeText={""}
                      classNameBoxAll="box-boletos-textos"
                    />

                    <M_BoxInfo
                      image={checkIconBlack}
                      title={"Integração por API's;"}
                      text={``}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"0px"}
                      widthIcon={"auto"}
                      fontSizeTitle={"1.1rem"}
                      fontSizeText={""}
                      classNameBoxAll="box-boletos-textos"
                    />


                    <M_BoxInfo
                      image={checkIconBlack}
                      title={"Registro de boleto em até 15 minutos."}
                      text={``}
                      colorTitle={""}
                      colorText={""}
                      heightIcon={"0px"}
                      widthIcon={"auto"}
                      fontSizeTitle={"1.1rem"}
                      fontSizeText={""}
                      classNameBoxAll="box-boletos-textos"
                    />
                  </ContainerFlexInfoBox>


                </ContainerBox>

                <ContainerMobile className="container-button">
                  <A_Button
                    background="#0b2d48"
                    color="#fff"
                    height={"4rem"}
                    width={"18rem"}
                    onClick={handleClickOpenModalBoletos}
                  >
                    <A_TextBody
                      content={"Solicite uma demonstração"}
                      fontFamily={"Nunito"}
                      fontSize={"1.1rem"}
                      fontWeight={"600"}
                      color={"#fff"}
                      toUppercase={false}
                    />
                  </A_Button>
                </ContainerMobile>
              </ContainerMobile>
            </ContainerGrid>
          }
        />
      </ContainerMobile>

      <A_ModalComponent
        isOpen={isOpenModalPremia}
        onRequestClose={handleClickCloseModalPremia}
        type={"premia"}
        children={
          <M_Form            
            title={""}
            titleButton={"Enviar solicitação"}
            subtitle={
              "Preencha o formulário abaixo que nossa equipe entrará em contato agendando o dia e horário para demonstrarmos nossas soluções."
            }
            isLogoExist={true}
            logo="premia"
            request="demonstration"
            onCloseModal={handleClickCloseModalPremia}
          />
        }
      />

      <A_ModalComponent
        isOpen={isOpenModalAntecipa}
        onRequestClose={handleClickCloseModalAntecipa}
        type={"antecipa"}
        children={
          <M_Form            
            title={""}
            titleButton={"Enviar solicitação"}
            subtitle={
              "Preencha o formulário abaixo que nossa equipe entrará em contato agendando o dia e horário para demonstrarmos nossas soluções."
            }
            isLogoExist={true}
            logo="antecipa"
            request="demonstration"
            onCloseModal={handleClickCloseModalAntecipa}
          />
        }
      />

      <A_ModalComponent
        isOpen={isOpenModalBoletos}
        onRequestClose={handleClickCloseModalBoletos}
        type={"boletos"}
        children={
          <M_Form            
            title={""}
            titleButton={"Enviar solicitação"}
            subtitle={
              "Preencha o formulário abaixo que nossa equipe entrará em contato agendando o dia e horário para demonstrarmos nossas soluções."
            }
            isLogoExist={true}
            logo="boletos"
            request="demonstration"
            onCloseModal={handleClickCloseModalBoletos}
          />
        }
      />
    </PrincipalContainer>
  );
};

export { T_Plataformas };
