import styled from 'styled-components'

interface IMargin {
	aplicarMargin?: boolean
}

const PrincipalContainer = styled.div`	
		.MuiBox-root  {
		padding: 0;		
	}

	.MuiTabs-flexContainer {	
		justify-content: space-evenly;
		background-color: #bdbdbd36;
		border-top: 2px solid #bebebed4;
	}

	.MuiButtonBase-root {
		height: 8.5rem !important;
	}

	.MuiTab-root {
		max-width: 33.33%;
		min-width: 33.33%;
		opacity: 0.6;
		border: 0.5px solid #9b9b9b;
		height: 10rem;
		filter: grayscale(1);
	}

	.MuiTab-root.Mui-selected {
		opacity: 1;
		filter: grayscale(0);
		border: 10px;
		box-shadow: 14px 24px 44px 4px rgb(0 0 0 / 30%);
		background: #fff;		
	}

	.MuiTabs-indicator {
		width: 50%;
		height: 8px;
		background-color: #2cccd9;
	}

	.tab1 {
		background-color: #0b2d48;
	}
	
	.tab2 {
		background-color: #2cccd9;	
	}	

	.image {
		width: 19rem;
	}

	@media (min-width: 1080px) and (max-width: 1480px) {
		.image {
			width: 14rem;
		}	
		
		.MuiButtonBase-root {
			height: 6rem !important;
		}	
  	}
`;

const ContainerGrid = styled.div<IMargin>`	
	display: grid;
	grid-template-columns: 50% auto;
	align-items: center;	
	margin-top: ${props => props.aplicarMargin ? "-1.6rem" : "0"} !important;
	position: relative;
	
	.container-button {
		position: absolute;
		bottom: 0rem;
		left: 20rem;
	}		

	.unic-button {
		bottom: -.5rem;
	}

	.unic-button-premia {
		bottom: -2.5rem;
	}	

	.unic-button-premia {
		bottom: -3.5rem;
	}	

	.unic-button-boleto {
		bottom: 2.5rem;
	}
	
	.container {
		padding-bottom: 2.2rem !important;	
	}	
	
	.img-session-antecipa {			
		margin-bottom: -4rem;
		margin-top: -1rem;
		width: 47rem;		
	}

	@media (min-width: 1080px) and (max-width: 1480px) {
		grid-template-columns: 60% auto;
		margin-top: ${props => props.aplicarMargin ? ".5rem" : "0"} !important;
		
		.container-button {
			position: relative;
		}	

		.unic-button {
			bottom: -6.8rem;
		}		
		
		.unic-button-boleto {
			bottom: -6.8rem;
		}

		.como-funciona {
			font-size: 1.2rem !important;
		}	

		.unic-button-boleto {
			top: 5rem;
			left: 10rem;
		}

		.antecipa-container {
			margin-top: -2rem !important;					
		}

		.img-session-antecipa {
			margin-top: -3rem !important;
			margin-bottom: -3rem;	
			margin-left: -8rem;	
		}

		.unic-button {
			top: 2.2rem;
			left: 9rem;
		}

		.premia-container {
			margin-top: -1rem !important;								
		}

		.unic-button-premia {
			top: 1rem;
			left: 15rem;
		}	

		.img-session-premia {
			margin-left: -1.5rem;
		}

	}


	@media (max-width: 1024px) {		
		display: flex;
		width: 100%;		
		flex-direction: column;		
		padding-bottom: 1.5rem;		
	}
`;

const ContainerBox = styled.div`	
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 90%;

	@media (min-width: 767px) and (max-width: 1024px) {
		width: 100%;		
		margin-top: 1rem;						
	}

	@media (max-width: 767px) {
		width: 100%;
	}
`;

const ContainerFlex = styled.div`	
	margin-top: -.3rem;
	
	.container-message {
		display: flex;
		align-items: center;			

		img {
			margin-top: .3rem;
		}
	}

	.unic {
		margin-top: 0.6rem;
	}

`

const ContainerDesktop = styled.div`
	display: block;	
		
	.img-session-boleto {		
		bottom: 0;
		margin-top: 3.1rem;
	}		

	@media (min-width: 1080px) and (max-width: 1500px) {
		.session-slide {
			height: fit-content !important;
			padding-bottom: 2rem;
		
			.img-session-premia, .img-session-antecipa {
				width: 37rem;								
			}		
			
			.img-session-boleto {
				width: 25.8rem;	
				margin-top: 2.1rem;
			}
		}	
		
		.session-boletos {			
			height: 36.9rem !important;
		}

		.session-premia {
			height: 39.5rem !important;
		}
	}

	
	@media (max-width: 1024px) {
		display: none;
	}
`;

const ContainerMobile = styled.div`
	display: none;

	@media (max-width: 1024px) {
		display: flex;		
		flex-direction: column;	

		.box-boletos-textos {			
			position: relative;
			padding-left: 1.4rem;

			img {
				position: absolute;				
				left: 0rem;
				top: 0rem;
				height: 1.5rem !important;
			}

			span {
				padding-left: 1rem;
			}
		}

		.img-boletos {
			margin: 3rem 0rem;
		}

		.antecipa {
			margin-top: 1rem;			
			background: transparent;
		}

		.container-mobile {
			margin-top: -5rem;
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 1.5rem;
			padding: 0rem 1rem;		
		}		

		.container-button {
			margin-top: 2rem;
			position: relative;					
		}	

		.box-mobile-infos {			
			display: grid;
			flex-direction: column;
			align-items: start;
			justify-content: left;			
			margin-bottom: -1rem;	
			grid-template-columns: 100%;						
		}

		.container-box-mobile {			
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			
			span {
				text-align: left;
			}

			img {
				margin-top: .5rem;
			}
		}
	
		.title-principal {
			font-size: 1.4rem;
		}

		.subtitle-principal {
			margin-top: -4rem;
			margin-bottom: 2rem;
			font-size: 1.2rem;
		}		

		.como-funciona {
			margin-top: 1rem;							
		}

		.como-funciona-antecipa,.como-funciona-boletos  {
			margin-top: -.5rem;
		}

		.unic {
			margin-top: .5rem;
		}
	}

	@media (max-width: 1024px) {
		.container-button {			
			left: 35%;					
		}	
	}

	@media (max-width: 767px) {
		.container-button {			
			left: 3rem;
		}	
	}
`

const ContainerFlexInfoBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: flex-start;
	gap: .5rem;
`;

export {
	PrincipalContainer,
	ContainerGrid,
	ContainerDesktop,
	ContainerMobile,
	ContainerFlex,
	ContainerBox,
	ContainerFlexInfoBox
}