import { api } from "../../service/api";
import { variables } from "../variables/variables";


export const handleDemonstracao = async (payload: any) => {
  try {
    let url = variables.solicitacaoDemonstracao
    return await api
      .post(url, { data: payload })
      .then((res: any) => {
        return res.data;
      })
      .catch(() => {
        throw Error("Erro ao solicitar demonstração");
      });
  } catch (error) {
    throw error;
  }
}