/* eslint-disable react/jsx-pascal-case */
import { FC, ReactNode } from "react";
import { A_Title } from "../../atomos/title";
import { ContainerChildren, Content } from "./styles";
import { A_Subtitle } from "../../atomos/subtitle";

interface IContainerSlide {
  title: string;
  children: ReactNode;
  colorTitle: string;
  subtitle?: string;
  classNameSubtitle?: string;
}

const M_ContainerSlides: FC<IContainerSlide> = ({
  title,
  children,
  colorTitle,
  subtitle,
  classNameSubtitle,
}) => {
  return (
    <Content>
      <A_Title
        title={title}
        color={colorTitle}
        fontFamily={"hero"}
        fontWeigth={"400"}
        fontSize={"2rem"}
        toUppercase={false}
        className="title-slide"
        notPaddingTop
      />

      <A_Title
        title={subtitle ? subtitle : ""}
        color={colorTitle}
        fontFamily={"hero"}
        fontWeigth={"400"}
        fontSize={"20px"}
        toUppercase={false}
        className="subtitle"
        notPaddingTop={false}
      />

      <ContainerChildren>{children}</ContainerChildren>
    </Content>
  );
};

export { M_ContainerSlides };
