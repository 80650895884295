import { Route, Routes } from "react-router-dom";
import { P_Initial } from "../pages/initial";
import { P_NaMidia } from "../pages/midia";
import { P_Termos } from "../pages/termos";
import { P_MessageConfirmacao } from "../pages/message-confirmacao";

const MainRouter: React.FC = () => (
  <Routes>
    <Route path="/" element={<P_Initial />} />
    <Route path="/namidia" element={<P_NaMidia />} />
    <Route path="/nossostermos" element={<P_Termos />} />
    <Route path="/messagemdeconfirmacao" element={<P_MessageConfirmacao />} />
  </Routes>
);

export { MainRouter };
