import { FC, useEffect, useState } from "react";
import { PrincipalContainer } from "./styles";
import { IoLogoWhatsapp } from "react-icons/io";

const A_ButtonBackToTop: FC = () => {
  const [scroll, setScrol] = useState<any>();
  const [hiddenHeader, setHeaderHidden] = useState(false);
  let linkApp =
    "https://api.whatsapp.com/send?phone=552139006975&text=Quero%20conhecer%20mais%20sobre%20a%20Planium%20Bank!";
    // 5502139006975
  useEffect(() => {
    const onScroll = () => {
      setScrol(window.scrollY);
    };
    if (onScroll >= scroll) {
      setHeaderHidden(true);
    } else {
      setHeaderHidden(false);
    }
    window.addEventListener("scroll", onScroll);
  }, [hiddenHeader, scroll]);

  return (
    <PrincipalContainer scroll={scroll} href={linkApp} target={"_blank"}>
      <IoLogoWhatsapp />
    </PrincipalContainer>
  );
};

export { A_ButtonBackToTop };
