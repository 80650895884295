import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --color-question: #888888;
    --color-question-selected: #fff
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    scroll-behavior: smooth; 
    overflow-x: hidden;    
    width: 100%;

    @media (min-width: 1080px) {
      font-size: 92% !important; 
    }
    
    @media (max-width: 1080px) {
      font-size: 92% !important;       
    }

    @media (max-width: 720px) {
      font-size: 93%;       
    }
  }  

  body{
    -webkit-font-smoothing: antialiased;  
    padding-right: 0 !important;   
    overflow-x: hidden;      

    .react-modal-overlay {      
      position: fixed;
      z-index: 9999 !important;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;    
    }

    .premia, .account, .boletos {
      background: rgba(44, 204, 217, 0.6);     
    }

    .antecipa {
      background: rgba(122, 94, 234, 0.6);    
    }

    .defeault-bank {
      background: rgba(0, 0, 0, 0.7);

      img {        
        margin-bottom: 2rem;
        margin-top: 1rem;
      }
    }    

    .react-modal-content {
      width: 100%;
      max-width: 500px;
      height: auto;     
      background: white;  
      overflow-y: auto;         
      padding: 3rem;
      position: relative;
      border-radius: 2rem;
      -webkit-animation: scale-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
      animation: scale-in 0.3s cubic-bezier(0.2150, 0.460, 0.450, 0.940);     
    }

    @keyframes scale-in {
      0% {
          -webkit-transform: scale(0);
          transform: scale(0);
      }
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
    }

    @media (max-width: 700px) {
      .react-modal-content {
        border-radius: 0rem;
        width: 100vw;
        height: 100vh !important;
      }
    }
  } 
`;

export { GlobalStyle };
