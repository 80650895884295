import styled from 'styled-components'


const PrincipalContainer = styled.div`
  width: 100%;

  .MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
  }

  @media (max-width: 700px) {
    .title {
      font-size: 90%;    
      margin-right: 1rem;
    }
    
    .img-arrow {      
      margin-left: 1rem;      
    }
  }
`;


export {
  PrincipalContainer
}