import styled from 'styled-components'

interface IIconProps {
  width: string;
  height: string;
  colorIcon?: string;
}

const PrincipalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;  
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
`

const Icon = styled.div<IIconProps>`
  svg {
    width: ${props => props.width};
    height: ${props => props.height}; 
    color: ${props => props.colorIcon};
  } 
`

export {
  ContainerFlex,
  PrincipalContainer,
  Icon
}