import styled from 'styled-components'

const Content = styled.div`
  max-width: 1300px;
  margin: auto;  
`

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: .8rem; 
`

const ContainerChildren = styled.div`
  margin-top: 4rem;
`

export {
  Content,
  ContainerFlex,
  ContainerChildren
}