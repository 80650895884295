import styled from 'styled-components'

interface IButtonBackToTopProps {
  scroll: number
}

const PrincipalContainer = styled.a<IButtonBackToTopProps>`
  display: ${props => props.scroll <= 130 ? 'none' : 'flex'};
  right: 0.6rem;
  bottom: .6rem;   
  border: none;
  padding: .5rem;
  transition: .3s;   
  position: fixed;
  right: .6rem;  
  cursor: pointer;  
  z-index: 9999;

  > svg {
    font-size: 3.2rem;
    color: green;   
  }
`;

export { PrincipalContainer }