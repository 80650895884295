export const ValidationTypes = {
  Required: 'required',
  IsEmail: 'isEmail',
  IsCNPJ: 'isCNPJ',
  IsCPF: 'isCPF',
  IsCEP: 'isCEP',
  IsTelefone: 'isTelefone',
  IsCadastrado: 'isCadastrado',
  ValidateLenght: 'validateLenght',
  ValidateCase: 'validateCase',
  ValidateNumber: 'validateNumber',
  CheckEqualPassword: 'checkEqualPassword',
  ValidateBirthDate: 'validateBirthDate',    
  ValidateDate: 'validadeDate',
  ValidateDateAtual: 'validateDateAtual',
  ValidateDateCNH: 'validadeDateCNH',
  ValidaExameToxicologico: 'validadeDateCNH',
  verificaCPF: "verificaCPF",
  ValidateCategoria: "ValidateCategoria"  
}