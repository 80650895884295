/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from "react";
import { A_Button } from "../../atomos/button";
import { A_Image } from "../../atomos/image";
import { A_ModalComponent } from "../../atomos/modal";
import { A_Subtitle } from "../../atomos/subtitle";
import { A_TextBody } from "../../atomos/text-body";
import { M_BoxInfo } from "../../moleculas/box-info";
import { M_Form } from "../../moleculas/form";
import { O_Session } from "../../organismos/section";
import { ContainerBenefits, ContainerFlex, ContainerGrid, PrincipalContainerCreditos } from "./styles";

const imgCredito = require("../../../assets/img-session-credito.png");
const checkIconBlack = require("../../../assets/verificar.png");
const background = require("../../../assets/img-background.png");
const logoCredito = require("../../../assets/planiumbank-credito.png")

const T_Creditos: FC = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  return (
    <O_Session
      heigth={"auto"}
      mobile="fit-content"
      tabled="fit-content"
      background={""}
      children={
        <>
          <PrincipalContainerCreditos imageFile={background}>
            <div className="container">
              {/* <A_Title
                title={"CRÉDITO SAÚDE"}
                color={"#000"}
                fontFamily={"Nunito"}
                fontWeigth={"700"}
                fontSize={"2.2rem"}
                toUppercase={false}
                className={"title"}
                notPaddingTop={false}
              /> */}
              <A_Image
                alt={"image"}
                width={"30rem"}
                src={logoCredito}
                height={"auto"}
                className="logo-credito"
              />


              <A_Subtitle className="subtitle" title={"Soluções completas de crédito para o mercado de saúde"} color={"#000"} fontFamily={"Nunito"} fontWeigth={"400"} fontSize={"1.2rem"} />
            </div>

            <ContainerGrid>
              <ContainerFlex>
                {/* <A_TextBody
                  content={
                    "O Planium Bank traz uma solução completa de crédito para o mercado de saúde privada. "
                  }
                  fontFamily={"Nunito"}
                  fontSize={"1.2rem"}
                  fontWeight={"400"}
                  color={"#000"}
                  toUppercase={false}
                  className={"paragraph"}
                /> */}


                <ContainerBenefits style={{marginTop: "-3rem"}}>
                  <A_TextBody
                    content={"Oferecemos uma plataforma completa de produtos de crédito que inclui:"}
                    fontFamily={"Nunito"}
                    fontSize={"1.2rem"}
                    fontWeight={"400"}
                    color={"#000"}
                    toUppercase={false}
                  />
                  <M_BoxInfo
                    image={checkIconBlack}
                    title={"Antecipação de Recebíveis;"}
                    text={``}
                    colorTitle={""}
                    colorText={""}
                    heightIcon={"20px"}
                    widthIcon={"auto"}
                    fontSizeTitle={"1.1rem"}
                    fontSizeText={""}
                    classNameBoxAll="box-benefits"
                  />
                  <M_BoxInfo
                    image={checkIconBlack}
                    title={"Financiamento de Equipamentos de Saúde;"}
                    text={``}
                    colorTitle={""}
                    colorText={""}
                    heightIcon={"20px"}
                    widthIcon={"auto"}
                    fontSizeTitle={"1.1rem"}
                    fontSizeText={""}
                    classNameBoxAll="box-benefits"
                  />
                  <M_BoxInfo
                    image={checkIconBlack}
                    title={"Financiamento de OPME (Órteses, Próteses e Materiais Especiais);"}
                    text={``}
                    colorTitle={""}
                    colorText={""}
                    heightIcon={"20px"}
                    widthIcon={"auto"}
                    fontSizeTitle={"1.1rem"}
                    fontSizeText={""}
                    classNameBoxAll="box-benefits"
                  />
                  <M_BoxInfo
                    image={checkIconBlack}
                    title={"Capital de Giro;"}
                    text={``}
                    colorTitle={""}
                    colorText={""}
                    heightIcon={"20px"}
                    widthIcon={"auto"}
                    fontSizeTitle={"1.1rem"}
                    fontSizeText={""}
                    classNameBoxAll="box-benefits"
                  />
                  <M_BoxInfo
                    image={checkIconBlack}
                    title={"Crédito Estruturado."}
                    text={``}
                    colorTitle={""}
                    colorText={""}
                    heightIcon={"20px"}
                    widthIcon={"auto"}
                    fontSizeTitle={"1.1rem"}
                    fontSizeText={""}
                    classNameBoxAll="box-benefits"
                  />
                </ContainerBenefits>

                <A_Button
                  background={"#0b2d48 "}
                  color={"#fff"}
                  width={"12rem"}
                  className={"button-action"}
                  height={"3rem"}
                  onClick={() => setIsOpenModal(true)}
                >
                  <A_TextBody
                    content={"Entre em contato"}
                    fontFamily={"Nunito"}
                    fontSize={"1rem"}
                    fontWeight={"600"}
                    color={"#fff"}
                    toUppercase={false}
                  />
                </A_Button>
              </ContainerFlex>

              <ContainerFlex>
                <A_Image
                  alt={"image"}
                  width={"45rem"}
                  src={imgCredito}
                  height={"auto"}
                  className="img-credito"
                />
              </ContainerFlex>
            </ContainerGrid>
          </PrincipalContainerCreditos>

          <A_ModalComponent
            isOpen={isOpenModal}
            onRequestClose={() => setIsOpenModal(false)}
            type={"defeault-bank"}
            children={
              <M_Form
                title={""}
                titleButton={"Confirmar"}
                subtitle={
                  "Preencha o formulário abaixo que nossa equipe entrará em contato agendando o dia e horário para demonstrarmos nossas soluções."
                }
                isLogoExist={true}
                logo="default"
                request="demonstration"
                onCloseModal={() => setIsOpenModal(false)}
              />
            }
          />
        </>
      }
    />
  );
};

export { T_Creditos };
