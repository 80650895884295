import styled from "styled-components";

export const Session = styled.div`  
  position: absolute;
  margin-top: -5.6rem;
 
  @media (min-width: 1080px) and (max-width: 1480px) {
    margin-top: -5.35rem;           
  }  
`;
