import { FC, ReactNode } from "react";
import Modal from "react-modal";

interface IModalProps {
  children: ReactNode;
  isOpen: boolean;
  onRequestClose: any;
  type: "premia" | "antecipa" | "account" | "boletos" | "defeault-bank";
}

const A_ModalComponent: FC<IModalProps> = ({
  children,
  isOpen,
  type,
  onRequestClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={"react-modal-content"}
      overlayClassName={`react-modal-overlay ${type}`}
    >
      {children}
    </Modal>
  );
};

export { A_ModalComponent };
