import styled from 'styled-components'

const PrincipalContainer = styled.div`
  position: fixed;
  z-index: 999999999;

  .CookieConsent {
    background: #000206 !important;
    box-shadow: 0px 0px 5px rgba(25, 25, 25, 0.8);    
    height: 7.5rem;    
    padding: 20px 80px;
    display: flex !important;
    align-items: center !important;
    font: 500 1.1rem "Nunito";
    width: 100% !important;           
  }

  button {    
    background: linear-gradient(#8062F7,#33C2DB) !important;
    color: #fff !important;
    height: 2.2rem; 
    width: 8.5rem;
    font: 700 .85rem "Nunito";
    text-transform: uppercase;
    border-radius: 1rem !important;
  }

  @media (max-width: 750px) {
    .CookieConsent {
      width: auto;
      padding: 0;
      height: auto;
    }

    button {
      width: 92vw !important;
      height: 3rem;
    }
  }
`;

export { PrincipalContainer }