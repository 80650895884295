/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from "react";
import { A_Title } from "../../atomos/title";
import { M_BackgroundTitleImage } from "../../moleculas/background-title-image";
import { O_Session } from "../../organismos/section";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { A_Image } from "../../atomos/image";
import { PrincipalContainer, ContainerQuestions, ContainerAll } from "./styles";
import {
  handleFaqPf,
  handleFaqPj,
  handleFaqServicoProdutos,
} from "../../../utils/requests/handle-faq";
import { M_CardSession } from "../../moleculas/card-questions-faq";

const imgFaqPj = require("../../../assets/img-conta-digital-pj.png");
const imgFaqPf = require("../../../assets/img-conta-digital-pf.png");
const imgFaqProdutosServicos = require("../../../assets/img-produto-servico.png");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const T_Faq: FC = () => {
  const [faqPF, setFaqPF] = useState([]);
  const [faqPJ, setFaqPJ] = useState([]);
  const [faqProdutoServico, setFaqProdutoServico] = useState([]);
  const [value, setValue] = useState(0);

  async function getFaqPf() {
    await handleFaqPf().then((res: any) => {
      setFaqPF(res);
    });
  }

  async function getFaqPJ() {
    await handleFaqPj().then((res: any) => {
      setFaqPJ(res);
    });
  }

  async function getProdutosServicos() {
    await handleFaqServicoProdutos().then((res: any) => {
      setFaqProdutoServico(res);
    });
  }

  useEffect(() => {
    getFaqPf();
    getFaqPJ();
    getProdutosServicos();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  return (
    <ContainerAll>
      <M_BackgroundTitleImage
        sessionPageBackground="homeEndMidia"
        children={
          <A_Title
            title={"Perguntas frequentes"}
            color={"#fff"}
            fontFamily={"Nunito"}
            fontWeigth={"700"}
            fontSize={"2rem "}
            toUppercase={true}
            className={"title"}
            notPaddingTop={false}
          />
        }
      />

      <O_Session
        heigth={"auto"}
        background={"#282828"}
        children={
          <PrincipalContainer>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  icon={
                    <A_Image
                      alt={"logo-premia"}
                      width={"5rem"}
                      src={imgFaqPf}
                      height={"auto"}
                    />
                  }
                  label={"Pessoa física"}
                  {...a11yProps(0)}
                />
                <Tab
                  icon={
                    <A_Image
                      alt={"logo-premia"}
                      width={"5rem"}
                      src={imgFaqPj}
                      height={"auto"}
                    />
                  }
                  label={"Pessoa jurídica"}
                  {...a11yProps(1)}
                />
                <Tab
                  icon={
                    <A_Image
                      alt={"logo-premia"}
                      width={"5rem"}
                      src={imgFaqProdutosServicos}
                      height={"auto"}
                    />
                  }
                  label={"Produtos e servicos"}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ContainerQuestions>
                {faqPF.map((faqpf: any) => {
                  return (
                    <>
                      <M_CardSession
                        title={faqpf.attributes.pergunta}
                        text={faqpf.attributes.resposta}
                      />
                    </>
                  );
                })}
              </ContainerQuestions>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContainerQuestions>
                {faqPJ.map((faqpj: any) => {
                  return (
                    <>
                      <M_CardSession
                        title={faqpj.attributes.pergunta}
                        text={faqpj.attributes.resposta}
                      />
                    </>
                  );
                })}
              </ContainerQuestions>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ContainerQuestions>
                {faqProdutoServico.map((faqProdServ: any) => {
                  return (
                    <>
                      <M_CardSession
                        title={faqProdServ.attributes.pergunta}
                        text={faqProdServ.attributes.resposta}
                      />
                    </>
                  );
                })}
              </ContainerQuestions>
            </TabPanel>
          </PrincipalContainer>
        }
      />
    </ContainerAll>
  );
};

export { T_Faq };
