import styled from 'styled-components'

interface IBackgroundProps {
  imageFile: string
}

interface ISessionProps {
  session: string
}

const BackgroundTitle = styled.div<IBackgroundProps>`
  background: no-repeat center/cover url(${props => props.imageFile});  
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;  
  justify-content: center; 
  position: relative;   
`;

const Overlay = styled.div<ISessionProps>`  
  width: 100%;
  height: 20rem;  
  background: ${props => props.session === "homeEndMidia" ? "transparent" : "#000"};
  position: absolute;
  opacity: .4;
`;

export {
  BackgroundTitle,
  Overlay
}