import styled from 'styled-components'

const PrincipalContainer = styled.div`
  max-width: 1250px;
  margin: auto;  

  @media (min-width: 1200px) and (max-width: 1480px) {
    max-width: 1200px;
  }

`

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);   
  gap: 2rem;

  @media (max-width: 900px) {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;   
  }
`

export {
  PrincipalContainer,
  ContainerGrid
}