import styled from 'styled-components'

interface ISubtitleProps {
  fontFamily: string
  fontSize: string
  fontWeight: string
  color: string
}

const Subtitle = styled.span<ISubtitleProps>`
  @font-face {
    font-family: "hero";
    src: url("../fonts/HeroLight-Regular.woff") format("woff"),
      url("../fonts/HeroLight-Regular.woff") format("woff2");
  }

  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};  
  margin-top: .8rem;
  text-align: center;    
`

export {
  Subtitle
}