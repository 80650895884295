/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { M_BoxInfo } from "../../moleculas/box-info";
import { M_ContainerClients } from "../../moleculas/container-session-clients";
import { M_FooterReferenceClients } from "../../moleculas/footer-reference-clients";
import { O_Session } from "../../organismos/section";
import { PrincipalContainer, ContainerGrid } from "./styles";

const iconBoletos = require("../../../assets/img-icon-boletos.png");
const iconInteracao = require("../../../assets/img-icon-interacao.png");
const iconParceiros = require("../../../assets/img-icon-parceiros.png");

const iconBoletosBranco = require("../../../assets/img-icon-boletos-branco.png");
const iconInteracaoBranco = require("../../../assets/img-icon-interacao-branco.png");
const iconParceirosBranco = require("../../../assets/img-icon-parceiros-branco.png");

const T_Corretora: FC = () => {
  return (
    <>
      <O_Session
        heigth={"fit-content"}
        mobile={"74rem"}
        tabled="51rem"
        background={"linear-gradient(to left, #213E9F, #63249E)"}
        children={
          <PrincipalContainer>
            <M_ContainerClients
              title={"Corretora"}
              colorText={"#fff"}
              colorTitle={"#fff"}
              subTitle={"Centralize seus recebimentos"}
              children={
                <>
                  <ContainerGrid>
                    <M_BoxInfo
                      title={"Angariação"}
                      text={
                        "Cobrança da Taxa de Angariação por PIX, boleto ou cartão integrada ao processo de vendas."
                      }
                      colorTitle={"#fff"}
                      colorText={"#fff"}
                      widthIcon={"4rem"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      heightIcon={"3.5rem"}
                      image={iconBoletosBranco}
                    />

                    <M_BoxInfo
                      image={iconInteracaoBranco}
                      title={"Conta Centralizadora"}
                      text={
                        "Conta específica para recebimento e pagamento de Prêmios, Agenciamentos e Comissões. Sem custo de transferência."
                      }
                      colorTitle={"#fff"}
                      colorText={"#fff"}
                      widthIcon={"4rem"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      heightIcon={"3.5rem"}
                    />

                    <M_BoxInfo
                      image={iconParceirosBranco}
                      title={"Integração"}
                      text={
                        "API's para integração com as principais plataformas de venda onLineSession e sistemas de gestão."
                      }
                      colorTitle={"#fff"}
                      colorText={"#fff"}
                      widthIcon={"4rem"}
                      fontSizeTitle={"1.2rem"}
                      fontSizeText={"1rem"}
                      heightIcon={"3.5rem"}
                    />
                  </ContainerGrid>
                  <M_FooterReferenceClients
                    title={
                      '" Nosso time perdia muito tempo emitindo e acompanhando boletos de taxa de angariação para nossos corretores. Agora, com o Planium Bank, além de recebermos comissões e agenciamento das Operadoras mais rapidamente, também resolvemos o problema da cobrança da taxa de angariação."'
                    }
                    text={
                      "Diretor Executivo de uma das 10 maiores Plataformas de SP."
                    }
                    colorTitle={"#fff"}
                    colorText={"#fff"}
                  />
                </>
              }
            />
          </PrincipalContainer>
        }
      />
    </>
  );
};

export { T_Corretora };
