import styled from 'styled-components'

const PrincipalContainer = styled.nav`
  padding: 0rem 1rem; 
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;    
  align-items: center;  

  .img-logo {
    margin-top: 2.5rem;
  }
  
  .link {
    font-size: 1.3rem;
  }

  .button {
    margin-top: 2rem;
    width: 13rem;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
      text-align: center;
      font-size: .95rem;
    }
  }

  .button-account-exist {
    border: 1px solid #fff;
    margin-top: 0rem;
  }  

  .button-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #fff !important;
  }
`;

const ContainerMenuBurguer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .toggle{
    font-size: 1.8rem;
    color: #fff;        
  }
`

export {
  PrincipalContainer,
  ContainerFlex,
  ContainerMenuBurguer
}