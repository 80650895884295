import { FC } from "react";
import { O_Header } from "../../components/organismos/header";
import { T_Api } from "../../components/templates/nossa-api";
import { T_Corretor } from "../../components/templates/corretor";
import { T_Corretora } from "../../components/templates/corretora";
import { T_Faq } from "../../components/templates/faq";
import { T_Footer } from "../../components/templates/footer";
import { T_Home } from "../../components/templates/home";
import { T_Operador } from "../../components/templates/operadora";
import { T_Plataformas } from "../../components/templates/plataformas";
import { Session } from "./styles";
import { M_Cookies } from "../../components/moleculas/cookies";
import { T_Creditos } from "../../components/templates/creditos";

export const P_Initial: FC = () => (
  <>
    <M_Cookies />

    <O_Header session="default" />

    <Session id="home" />
    <T_Home />

    <Session id="operadora" />
    <T_Operador />

    <Session id="corretora" />
    <T_Corretora />

    <Session id="corretor" />
    <T_Corretor />

    <Session id="plataformas" />
    <T_Plataformas />

    <Session id="credito" />
    <T_Creditos />
    
    <Session id="nossaApi" />
    <T_Api />

    <Session id="faq" />
    <T_Faq />

    <T_Footer />
  </>
);
