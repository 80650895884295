/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { listApps } from "../../../utils/listas/apps";
import { listContatos } from "../../../utils/listas/contatos";
import { listPoliticas } from "../../../utils/listas/politicas";
import { listRedeSocial } from "../../../utils/listas/redesSociais";
import { A_Image } from "../../atomos/image";
import { A_TextBody } from "../../atomos/text-body";
import { M_Footer } from "../../moleculas/box-rede-social";
import { O_Session } from "../../organismos/section";
import { PrincipalContainer, ContainerFlex, ContainerGrid } from "./styles";

const T_Footer: FC = () => {
  return (
    <>
      <O_Session
        heigth={"25rem"}
        mobile={"70rem"}
        tabled="35rem"
        background={"linear-gradient(to left,#213E9F,#63249E)"}
        children={
          <PrincipalContainer>
            <ContainerGrid>
              <ContainerFlex>
                <A_TextBody
                  content={"NOSSAS REDES"}
                  fontFamily={"Nunito"}
                  fontSize={"1.3rem"}
                  fontWeight={"500"}
                  color={"#fff"}
                  toUppercase={false}
                />
                {listRedeSocial.map((rs: any) => {
                  return (
                    <>
                      <M_Footer
                        key={rs.id}
                        icon={rs.icon}
                        title={rs.name}
                        colorTitle={"#fff"}
                        widthIcon={"1.5rem"}
                        fontSizeTitle={"1rem"}
                        heightIcon={"auto"}
                        colorIcon={"#fff"}
                        path={rs.link}
                        colorText={""}
                      />
                    </>
                  );
                })}
              </ContainerFlex>

              <ContainerFlex className="container-apps">
                <A_TextBody
                  content={"BAIXE O APP"}
                  fontFamily={"Nunito"}
                  fontSize={"1.35rem"}
                  fontWeight={"500"}
                  color={"#fff"}
                  toUppercase={false}
                  className={"title-apps"}
                />
                {listApps.map(a => {
                  return (
                    <>
                      <A_Image
                        alt={a.alt}
                        width={"12rem"}
                        src={a.icon}
                        height={"auto"}
                      />
                      ;
                    </>
                  );
                })}
              </ContainerFlex>

              <ContainerFlex className="container-politicas">
                <A_TextBody
                  content={"NOSSAS POLÍTICAS"}
                  fontFamily={"Nunito"}
                  fontSize={"1.35rem"}
                  fontWeight={"500"}
                  color={"#fff"}
                  toUppercase={false}
                />
                {listPoliticas.map((p: any) => {
                  return (
                    <>
                      <M_Footer
                        key={p.id}
                        icon={null}
                        title={p.name}
                        colorTitle={"#fff"}
                        widthIcon={"1.5rem"}
                        fontSizeTitle={"1rem"}
                        heightIcon={"auto"}
                        colorIcon={"#fff"}
                        path={p.link}
                        colorText={"#fff"}
                      />
                    </>
                  );
                })}
              </ContainerFlex>

              <ContainerFlex className="container-contatos">
                <A_TextBody
                  content={"CONTATOS"}
                  fontFamily={"Nunito"}
                  fontSize={"1.35rem"}
                  fontWeight={"500"}
                  color={"#fff"}
                  toUppercase={false}
                />
                {listContatos.map((c: any) => {
                  return (
                    <>
                      <M_Footer
                        key={c.id}
                        icon={null}
                        title={c.name}
                        colorTitle={"#fff"}
                        widthIcon={"1.5rem"}
                        fontSizeTitle={"1rem"}
                        heightIcon={"auto"}
                        colorIcon={"#fff"}
                        path={c.link}
                        colorText={"#fff"}
                      />
                    </>
                  );
                })}
              </ContainerFlex>
            </ContainerGrid>

            <A_TextBody
              content={
                "Não ligamos para você por esse telefone. Também não solicitamos dados pessoais, senha da conta, código de transação por telefone. Este número é apenas para você fazer ligações para o Planium Bank."
              }
              fontFamily={"Nunito"}
              fontSize={"1rem"}
              fontWeight={"400"}
              color={"#fff"}
              toUppercase={false}
              className={"text-rodape"}
            />
          </PrincipalContainer>
        }
      />
    </>
  );
};

export { T_Footer };
