/* eslint-disable react/jsx-pascal-case */
import { FC } from "react";
import { A_TextBody } from "../../atomos/text-body";
import { FooterContainer } from "./styles";

interface IFooterProps {
  title: string;
  text: string;
  colorTitle: string;
  colorText: string;
}
const M_FooterReferenceClients: FC<IFooterProps> = ({
  title,
  colorText,
  colorTitle,
  text,
}) => {
  return (
    <FooterContainer>
      <A_TextBody
        content={title}
        fontFamily={"Nunito"}
        fontSize={"1.1rem"}
        fontWeight={"400"}
        color={colorTitle}
        toUppercase={false}
        className={"title"}
      />
      <A_TextBody
        content={text}
        fontFamily={"Nunito"}
        fontSize={"1rem"}
        fontWeight={"700"}
        color={colorText}
        toUppercase={false}
        className="text"
      />
    </FooterContainer>
  );
};

export { M_FooterReferenceClients };
